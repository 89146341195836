@import '../../../assets/styles/base/mixins';
@import '../../../assets/styles/base/variables';

.voucher-brand-custom {
    padding: 20px;
    position: relative;
    border-radius: 4px;
    @include clearfix;
}

.voucher-brand-text {
    width: 60%;
    float: left;
    font-weight: 600;

    p {
        font-weight: 600;
        margin: 0;
        font-size: $font-size;

        @media (max-width: $screen-xs-max) {
            font-size: 12px;
        }
    }

    strong {
        color: $brand-primary;
    }

    &__title {
        color: $text-dark;
        margin-bottom: 10px;
        font-size: $font-size-4;
    }

    &__desc {
        color: $text-light-dark;
        font-size: $font-size-2;
    }
}

.voucher-brand-logo {
    width: 40%;
    float: left;
    text-align: right;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;

    img {
        width: 100%;
        max-width: 130px;
    }

    @media (max-width: $screen-xs-max) {
        right: 10px;

        img {
            max-width: 130px;
        }
    }
}

.sharoo-voucher {
    .voucher-brand-text__title, .voucher-brand-text__desc {
        color: #FFF;
    }
}

.comparis-voucher {
    background: #fff;
    border: 1px solid #6c0;
    color: #333;
}

.upto-voucher {
    background: #31ccb0;
    display: table;
    width: 100%;

    .voucher-brand-text__title, .voucher-brand-text__desc, .voucher-brand-text-show-more, .voucher-brand-more-description {
        color: #FFF;
    }

    .voucher-brand-text__desc, .voucher-brand-text-show-more, .voucher-brand-more-description {
        font-size: $font-size-2 - 1;
        font-weight: normal;

        p {
            font-size: $font-size-2 - 1;
            font-weight: normal;
        }
    }

    .voucher-brand-logo {
        width: 90px;
        height: 90px;
        float: left;
        background: #fff;
        position: relative;
        right: 0;
        left: 0;
        top: 0;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        display: table-cell;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        border: 1px solid #ccc;

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            padding: 5px;
        }
    }

    .voucher-brand-text {
        display: table-cell;
        float: right;
        width: calc(100% - 110px);
        padding-right: 100px;
        @media screen and (max-width: 600px) {

            padding-right: 0;

        }
    }

    .voucher-brand-icon-more {
        position: absolute;
        right: 30px;
        top: 55px;
        cursor: pointer;
        float: right;

        i {
            color: #fff;
        }

        &-opened {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
        }

        &-closed {
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }


        @media screen and (max-width: 600px) {
            position: relative;
            top: 10px;
            right: 15px;
        }
    }

    .voucher-brand-text-show-more {
        text-decoration: underline;
        cursor: pointer;
        display: inline-block;
        margin: 5px 0 15px 0;
    }

    .voucher-brand-more-description {
        display: table;
        color: #fff;
        margin-top: 50px;
        padding-left: 100px;

        ul {
            list-style-type: none;
            margin: 0;
        }

        li {
            margin-bottom: 15px;
            display: table;

            &:last-child {
                margin-bottom: 0;
            }

            span:first-child {
                background: #293F4A;
                width: 30px;
                height: 30px;
                line-height: 30px;
                margin-right: 15px;
                text-align: center;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                display: block;
            }

            span:last-child {
                display: table-cell;
            }
        }

        @media screen and (max-width: 600px) {
            width: 100%;
            padding-left: 0;

            li {
                margin-bottom: 5px;
            }
        }
    }

}

.ryd-voucher {
    background: #3232A4;
    display: table;
    width: 100%;

    .voucher-brand-text {
        width: 100%;

        &__title {
            text-transform: uppercase;
            margin-bottom: 0;
            height: 75px;
            display: table-cell;
            vertical-align: middle;
            font-size: 16px;
            width: 100%;

            @media (max-width: 767px) {
                font-size: 13px;
                height: 60px;
            }
        }

        &__desc {
            display: table-cell;
            vertical-align: middle;
            text-align: right;
            right: 0;
        }


    }

    .voucher-brand-logo {
        display: none;
    }

    .voucher-brand-text__title, .voucher-brand-text__desc, .voucher-brand-text-show-more, .voucher-brand-more-description {
        color: #FFF;
    }

    .voucher-brand-text__desc, .voucher-brand-text-show-more, .voucher-brand-more-description {
        font-size: $font-size-2 - 1;
        font-weight: normal;

        p {
            font-size: $font-size-2 - 1;
            font-weight: normal;
        }
    }


    .voucher-brand-text__content {
        display: table-cell;
        vertical-align: middle;
    }

    .voucher-brand-text__items {
        display: table;
        width: 100%;
    }

    .voucher-brand-text__item {
        display: table-cell;
        padding: 0 15px;
        font-weight: bold;
        vertical-align: middle;

        &:nth-child(1) {
            padding-left: 0;
        }

        &:nth-child(2) {
            padding: 0;
        }

        &:nth-child(3) {
            padding-right: 0;

            img {
                max-width: 35px !important;
            }
        }

        img {
            max-width: 70px;
            object-fit: cover;
        }
    }
}
