@mixin clearfix() {
    &:after {
        content: '';
        clear: both;
        display: block;
    }
}

@mixin button-bg($bg-color, $color, $outline-color: 'none') {
    $bg-color-hover: lighten($bg-color, 10%);
    background-color: $bg-color;
    color: $color;

    // if outline color is defined & is not default (none)
    @if ($outline-color != 'none') {
        box-shadow: inset 0px 0px 0px 2px $outline-color;
    }

    &:active,
    &:focus {
        color: $color;
    }

    &[disabled] {
        box-shadow: none;
        background-color: $btn-disabled;
        color: #fff !important;

        // if outline color is defined & is not default (none)
        @if ($outline-color != 'none') {
            box-shadow: inset 0px 0px 0px 2px $btn-disabled;
        }
    }

    &:not([disabled]):hover {
        background-color: $bg-color-hover;
        color: $color;

        // if outline color is defined & is not default (none)
        @if ($outline-color != 'none') {
            box-shadow: inset 0px 0px 0px 2px $outline-color;
            color: $bg-color;
            background-color: $color;
        }
    }
}
