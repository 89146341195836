div.react-datepicker {
    padding: 5px 5px;
    border-radius: 12px;
    max-width: 100%;

    @media (max-width: $screen-sm-min) {
        top: 50px;
        //top: 50%;
        //transform: translateY(-50%);
    }

    @media (max-width: $screen-smaller-xs) {
        font-size: 14px;
    }

    @media (min-width: $screen-sm-min) {
        box-shadow: 0 0 14px 0 rgba(159, 159, 159, 0.5);
    }
}

div.react-datepicker__current-month {
    background: #fafafa;
    width: 90%;
    padding: 8px 8px;
    color: #000;
    font-weight: normal;
    margin: 10px auto 10px auto;
    border-radius: 12px;
    @media (max-width: $screen-smaller-xs) {
        margin: 5px auto;
        padding: 5px 0;
    }
}

.react-datepicker__month-container {
    @media (max-width: $screen-sm-min) {
        float: none !important;
    }
}

.react-datepicker__header {
    border-bottom: 0;
}


div.react-datepicker {
    border: 0;
}

div.react-datepicker__triangle {
    display: none;
}

div.react-datepicker {
    font-family: $font-atto;
    font-size: 16px;
}

div.react-datepicker__header {
    text-align: center;
    background-color: transparent;
    border-bottom: 0;
}

button.react-datepicker__navigation {
    top: 26px;
    outline: none;
}

button.react-datepicker__navigation--previous,
button.react-datepicker__navigation--next {
    border: solid #959595;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    top: 40px;
}

button.react-datepicker__navigation--previous {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    left: 11%;
}

button.react-datepicker__navigation--next {
    right: 11%;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

div.react-datepicker__day--selected {
    background-color: $brand-primary;
}

div.react-datepicker-popper {
    top: 50% !important;
    transform: translateY(-50%) !important;
    right: 0;
    left: 0;
    position: fixed !important;
    text-align: center;
    background: rgba(0, 0, 0, .7);
    height: 100%;

    @media (min-width: $screen-sm-min) {
        position: absolute !important;
        transform: none !important;
        top: 65px !important;
        right: 0 !important;
        left: auto !important;
        background: none;
        height: auto;
    }
}

div.react-datepicker__input-container input[readonly][readonly] {
    border: solid 1px #e5eced !important;
    cursor: pointer;
}

div.react-datepicker__day-name,
div.react-datepicker__day {
    width: 38px;
    height: 38px;
    line-height: 2.5;
    margin: 0 2px;

    &:hover {
        border-radius: 12px;
    }

    @media (max-width: $screen-smaller-xs) {
        width: 30px;
        height: 30px;
        line-height: 2;
        margin: 0 1px;
    }
}

div.react-datepicker__day--keyboard-selected,
div.react-datepicker__day--selected {
    background-color: #754ceb;
    border-radius: 12px;

    &:hover {
        background-color: darken(#754ceb, 5%);
        border-radius: 12px;
    }
}
