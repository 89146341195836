@font-face
{
    font-family: 'Eina';
    font-weight: 400;
    src: url($font-path + 'Eina01/Eina01-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Eina';
    font-weight: 500;
    src: url($font-path + 'Eina01/Eina01-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'Eina';
    font-weight: 700;
    src: url($font-path + 'Eina01/Eina01-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Atto';
    font-weight: 300;
    src: url($font-path + 'Atto/atto_light-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'Atto';
    font-weight: 400;
    src: url($font-path + 'Atto/atto-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'Atto';
    font-weight: 500;
    src: url($font-path + 'Atto/atto_medium-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'Atto';
    font-weight: 700;
    src: url($font-path + 'Atto/atto_bold-webfont.ttf') format('truetype');
}
