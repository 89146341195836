@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/plugins/spinner-linear';
@import '../../../assets/styles/plugins/spinner-circular';

/* circular */
.loader-global {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;

    > .spinner {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        margin: 0;

        > div {
            background-color: #fff;
        }
    }
}

/* linear */
.loader-service-check,
.loader-form-vehicle,
.loader-button-funnel {
    margin: 0;
    display: inline;
    position: absolute;
    height: 20px;
    width: 20px;
}

.loader-button-funnel {
    height: 25px;
    width: 25px;
    right: 15px;

    .sk-circle:before {
        background-color: #fff;
    }
}

.loader-service-check {
    top: 30px;
    left: 0;
    background: #f8f9fa;
    
    @media (min-width: $screen-md-min) {
        top: 40px;
    }
}

/* linear */
.loader-form-vehicle {
    top: 12px;
    right: 10px;
}
