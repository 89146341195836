@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.baloise-link-container {
    width: 100%;
    padding: 20px 20px 10px 20px;
    border-top: 1px solid #f8f8f8;
    background-color: #fff;
    display: block;
    margin-bottom: 15px;
    box-shadow: 0 2px 24px -3px rgba(211, 211, 211, 0.5), 0 2px 5px -1px rgba(171, 171, 171, 0.5);
    position: relative;
    border-radius: 4px;

    @media (min-width: $screen-lg-min) {
        float: left;
        padding: 20px 20px;
    }
}

.baloise-link,
.baloise-link.container-fluid,
.baloise-link-text,
.baloise-link-price {
    padding: 0;
}

.baloise-link-logo {
    float: left;
    padding: 0;

    img {
        width: 40px;
        height: 40px;
        padding: 2px 2px;
        border: 1px solid #eaeaea;
        object-fit: contain;
        border-radius: 2px;

        @media (min-width: $screen-lg-min) {
            width: 70px;
            height: 70px;
            padding: 10px 10px;
            border-radius: 4px;
        }
    }
}

.baloise-link-text {
    padding: 0 0 30px 0;
    border-bottom: 1px solid #eaeaea;

    @media (min-width: $screen-lg-min) {
        border-bottom: 0;
        padding: 0;
        position: relative;
    }
}


.baloise-link-about,
.baloise-link-price {
    p {
        font-size: 10px;
        margin: 0;
        line-height: 1.3;
        color: #000;

        @media (min-width: $screen-lg-min) {
            font-size: 12px;
        }

        &.sponsored {
            margin: 0;
            color: #ccc;
        }
    }
}

.baloise-link-about {
    margin-left: 50px;
    width: calc(100% - 50px);

    @media (min-width: $screen-lg-min) {
        margin-left: 85px;
        width: calc(100% - 105px);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    h5 {
        color: #0e399d;
        font-size: 10px;
        line-height: 1;
        margin: 5px 0;
        font-family: $font-eina;
        font-weight: 700;

        @media (min-width: $screen-lg-min) {
            font-size: 12px;
            margin: 2px 0 0 0;
        }
    }

    .sponsored {
        display: none;

        @media (min-width: $screen-lg-min) {
            display: block;
        }
    }
}

.baloise-link-price {
    @include clearfix;
    padding: 10px 0 0 0;
    position: relative;

    @media (min-width: $screen-lg-min) {
        padding: 0;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }

    .sponsored {
        display: inline-block;
        padding: 12px 0;

        @media (min-width: $screen-lg-min) {
            display: none;
        }
    }

    button {
        line-height: 1.3;
        float: right;
        margin: 0;
        font-size: 12px !important;
        padding: 10px 15px !important;
    }

    > div {
        position: relative;

        > p, h5 {
            position: relative;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
