@import '../../assets/styles/base/variables';

.confirm-page {
    max-width: 1000px;
    width: 90%;
    margin: 50px auto 0;

    &__overview-item {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 10px;

        &--total {
            font-size: $font-size-6;
            font-weight: 700;
        }

        &__siblings {
            padding-left: 15px;
            margin: 10px 0;
            color: $text-lighter;
        }
    }

    &__dotted-separator {
        margin-top: 50px;
        border: 1.5px dashed $border-color;
    }
}

.confirm-page__section {
    background-color: #fff;
    padding: 35px 120px;
    margin-bottom: 30px;

    @media (max-width: $screen-sm-min) {
        padding: 35px 35px;
    }

    &:last-child {
        margin-bottom: 200px;
    }

    &--congrats {
        padding: 30px 240px;
        font-size: $font-size-2;

        @media (max-width: $screen-sm-min) {
            padding: 35px 35px;

            > h2 {
                font-size: 26px;
            }
        }

        @media (min-width: $screen-sm-min) {
            padding: 30px 120px;
        }
    }

    &--share {
        padding: 30px 35px;
        font-size: $font-size-2;

        .btn-fb {
            background-color: #4367b2;
            color: #fff;
            margin: 0 10px;
        }
        .btn-tw {
            background-color: #4fbaf4;
            color: #fff;
            margin: 0 10px;
        }
    }

    &--learn-more {
        font-size: 19px;
        font-weight: 700;
        color: $text-lighter;
        margin: 0;
    }

    &__img {
        width: 100px;
    }
}
