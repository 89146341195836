@import '../../../assets/styles/base/variables';

.modal-error {
    margin-top: 40px;
    &__message {
        color: $alert-danger-primary;
        margin-bottom: 25px;
    }
    &__not-found {
        padding: 25px 25px;
        margin: 15px 0;
        border-radius: 4px;
        position: relative;
        text-align: center;
        background-color: $alert-danger-secondary;
        border: 1px solid $alert-danger-primary;
        > p {
            font-weight: 500;
            color: #000;
            margin: 0;
        }
    }
}
