@import '../../assets/styles/base/variables';
@import '../../assets/styles/base/mixins';

.auth {
    padding: 25px 25px;

    .header-auth-text .btn{
        font-size: 15px;
        font-weight: 600;
    }
    .header-auth-text {
        margin-bottom: 30px;
        font-size: 15px;
        text-align: center;
        color: $text-light-dark;
        font-weight: 600;
    }

    .font-size-2 {
        font-size: $font-size-2;
    }

    .text-strong {
        color: $text-color;
    }

    .text-light {
        color: $text-lighter;
    }
}

.checkout {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: right;
    padding: 0 15px;

    .total-price {
        font-size: $font-size-6;
        text-align: right;
        margin-top: 10px;
    }

    .show-details {
        font-size: $font-size;
        font-weight: 700;
        text-align: right;
        color: $text-lighter;
    }

    .btn {
        margin: 0;
        padding: 14px 50px !important;
        font-weight: bold;
        font-size: $font-size;
    }

    @media (max-width: $screen-md-min) {
        position: fixed;
        bottom: 0;
        z-index: 1000;
        width: 100%;
        margin: 0;
        left: 0;
        padding: 15px 20px;
        background-color: #FFF;
        box-shadow: 0 2px 37px -11px rgba(210, 210, 210, 0.5), 0 2px 5px -1px rgba(171, 171, 171, 0.5);
        text-align: center;
    }
}

.social-media-login {
    padding: 0 20px;

    &__button {
        font-weight: 700;
        font-size: 17px;
        text-align: center;
        color: #ffffff;
        padding: 15px 50px;
        cursor: pointer;
        margin: 10px 0;
        border-radius: 5px;

        &--fb {
            background-color: #415daf;
        }

        &--go {
            background-color: #e74c3c;
        }
    }
}

.line-word-separator {
    text-align: center;
    border-bottom: 1px solid $line-word-separator;
    line-height: 0.25em;
    margin: 35px 20px;
}

.line-word-separator span {
    background: #FFF;
    padding: 0 20px;
    font-size: 12px;
    color: $text-light-dark;
    font-weight: bold;
}

.logged-user-data {
    font-size: $font-size;
    color: $text-light-dark;
    margin-bottom: 20px;
    font-weight: bold;

    img{
        width: 40px;
    }

    &__name{
        color: $brand-primary;
    }

    b {
        color: $text-color;
        font-weight: normal;
    }

    .btn--link{
        font-size: $font-size;
        color: $brand-black;
        font-weight: bold;
    }
}

.booking-total-price{
    color: #919eab;
    font-size: 20px;
    font-weight: 700;
    text-align: right;
    margin-left: -10px;

    &__has-discount {
        text-decoration: line-through;
        color: #9eacb9;
    }

    &__discount{
        font-size: $font-size-4;
        color: #008dff;

        &--discount-value{
            font-size: 14px;
            color: #dd122c;
        }
    }
}

.label-has-link{
    color: $text-light-dark;

    a{
        color: $text-color;
    }
}