@import '../../../../../resources/sass/web/base/variables';
@import '../../../../../resources/sass/web/base/mixins';

.filter-list-dropdown-container {
    position: relative;
}

.filter-bar {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 37px -11px rgba(210, 210, 210, 0.5), 0 2px 5px -1px rgba(171, 171, 171, 0.5);
    cursor: pointer;
    position: relative;
    
    &__title {
        width: 100px;
        float: left;
        padding: 15px 15px;
        
        @media (min-width: $screen-md-min) {
            width: 80px;
        }
        
        > p {
            margin-left: 10px;
            display: inline;
            font-weight: 700;
            
            @media (min-width: $screen-md-min) {
                margin-left: 0;
            }
        }
        
        .icon {
            @media (min-width: $screen-md-min) {
                display: none;
            }
        }
    }
    
    &__open {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 100%;
        border-radius: 4px;
        background: -moz-linear-gradient(left, rgba(255,255,255,0) 2%, rgba(255,255,255,1) 52%, rgba(255,255,255,1) 99%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(left, rgba(255,255,255,0) 2%,rgba(255,255,255,1) 52%,rgba(255,255,255,1) 99%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to right, rgba(255,255,255,0) 2%,rgba(255,255,255,1) 52%,rgba(255,255,255,1) 99%,rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
        
        @media(max-width: $screen-md-min) {
            pointer-events: none;
        }
        
        @media(min-width: $screen-md-min) {
            background-color: #fff;
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            text-align: center;
            padding: 15px 15px;
            float: right;
        }
        
        .icon {
            top: 3px;
            position: relative;
            
            @media (max-width: $screen-md-min) {
                display: none;
            }
        }
    }
}

.selected-filter-list {
    padding: 11px 0 11px 0;
    width: calc(100% - 120px);
    overflow-x: auto;
    white-space: nowrap;
    cursor: default;
    
    &--no-filter {
        margin: 0;
        font-size: 14px;
        color: #919eab;
        padding: 15px;
        
        > p {
            margin: 0;
        }
    }
}

.selected-filter {
    border-radius: 4px;
    background-color: #effaff;
    font-weight: 500;
    color: #345570;
    padding: 6px;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    font-size: 12px;
    
    .icon {
        margin-left: 10px;
        font-weight: 700;
    }
}

.filter-list-dropdown {
    left: 0;
    overflow: scroll;
    top: 10%;
    background-color: #fff;
    border-radius: 4px;
    position: fixed;
    width: 100%;
    height: 90%;
    z-index: 2;
    padding-bottom: calc(20% + 30px + 20px); // buttons height + paddings
    
    @media (min-width: $screen-md-min) {
        overflow: hidden;
        position: absolute;
        height: 315px;
        box-shadow: 0 2px 37px -11px rgba(188, 188, 188, 0.5), 0 2px 5px -1px rgba(72, 72, 72, 0.5);
        transform: translate(-15px, -15px);
        width: calc(100% + 30px);
        -webkit-backface-visibility: hidden;
    }

    div.zp-container {
        margin-bottom: 0;
    }
}

.filter-list-dropdown-header {
    @include clearfix;
    
    &__title,
    &__close {
        padding: 15px 15px;
        width: 50%;
        float: left;
        
        > p {
            margin: 0;
            font-weight: 700;
        }
    }
    
    &__title {
        > p {
            font-size: 16px;
        }
    }
    
    &__close {
        text-align: right;
        cursor: pointer;
        
        > .icon {
            font-size: 20px;
        }
        
        &--mobile {
            @media (min-width: $screen-md-min) {
                display: none;
            }
        }
        
        &--desktop {
            display: none;
            
            @media (min-width: $screen-md-min) {
                display: block;
            }
            
            > p {
                color: #bac7d5;
                font-size: 14px;
                display: inline;
            }
            
            .icon {
                margin-left: 10px;
            }
        }
    }
}

.filter-list-dropdown-footer {
    @include clearfix;
    padding: 15px 15px;
    display: none;
    text-align: right;
    
    @media (min-width: $screen-md-min) {
        display: block;
    }
    
    a {
        margin-left: 15px;
        cursor: pointer;
    }
}

.filter-list-dropdown-buttons {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0 2px 15px 0 rgba(201, 201, 201, 0.5);
    width: 100%;
    @include clearfix;
    
    button {
        font-size: 14px;
    }
    
    &__item {
        width: 50%;
        float: left;
        padding: 15px 15px;
        
        &__left {
        
        }
    }
    
    @media (min-width: $screen-md-min) {
        position: absolute;
        width: 50%;
        float: right;
        right: 0;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        
        &__item {
            padding: 10px 15px;
            
            button {
                margin: 0;
                padding: 15px 0 !important;
            }
        }
    }
}

body.drop-shadow {
    overflow: hidden;
    
    &:before {
        content: " ";
        position: fixed;
        background: rgba(0, 0, 0, .75);
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 1;
    }
}

.filter-section {
    @include clearfix;
    
    @media (min-width: $screen-md-min) {
        &:first-child {
            padding-right: 15px;
        }
        
        &:last-child {
            padding-left: 15px;
        }
    }
    
    .zp-container {
        margin-top: 0;
        margin-bottom: 45px;
    }
    
    > h5 {
        color: #919eab;
        font-size: 16px;
        font-weight: 500;
    }
}
