@import '../base/variables';
@import '../base/mixins';

.main {
    float: left;
    width: 100%;
    @include clearfix;

    &:not(&--garage){
        @media (min-width: $screen-md-min) {
            width: calc(100% - 375px);
            margin-left: 375px;
            height: 100%;
            padding: 20px;
        }
    }

    @media (min-width: $screen-lg-min) {
        padding: 40px;
    }

    &__title {
        margin: 30px 10px 30px 10px;

        @media (min-width: $screen-md-min) {
            margin: 0 0 30px 0;
        }
    }

    &--garage{
        @media (min-width: $screen-lg-min) {
            width: 1100px;
            margin-left: 375px;
            height: 100%;
            padding: 20px;
        }
    }

    // modifiers
    &--no-padding {
        padding: 0;
    }

    &--center{
        margin: auto;
        float: none;
    }
}
