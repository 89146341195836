@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.modal-container {
    background-color: rgba(0, 0, 0, 0.85);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    z-index: 99;

    &--additionalService {
        padding: 0;

        @media (min-width: $screen-md-min) {
            display: none;
        }
    }
}

.modal {
    display: block;
    opacity: 1;
    left: 0;
    border-radius: 4px;
    margin: 0 auto;
    width: 100%;
    background-color: #fff;
    padding: 20px 20px;
    top: 50%;
    position: relative;
    transform: translateY(-65%);

    @media (min-width: $screen-md-min) {
        width: 40%;
    }

    &__close {
        width: 100%;
        text-align: right;
        position: absolute;
        box-sizing: border-box;
        padding: 20px;
        top: 0;
        left: 0;
    }

    &__close-icon {
        font-size: 26px;
        cursor: pointer;
    }

    &__header {
        padding: 5px 0 25px 0;

        > h5 {
            margin: 0;
        }
    }

    &__footer {
        position: fixed;
        bottom: 0;
        background-color: #fff;
        box-shadow: 0 2px 15px 0 rgba(201, 201, 201, 0.5);
        width: 100%;
        z-index: 999;
        @include clearfix;

        @media (min-width: $screen-md-min) {
            display: none;
        }

        &__item {
            width: 50%;
            float: left;
            padding: 0 15px;
        }
    }

    button {
        margin: 0;

        &:last-child {
            margin-top: 10px;
        }

        &:first-child {
            margin-top: 25px;
        }
    }

    &--additionalService {
        @media (max-width: $screen-md-min) {
            height: 90%;
            padding: 0;
            transform: translate(0, 0);
            max-width: 100%;
            top: 10%;

            .modal__header p {
                margin-top: 20px;
                margin-bottom: 0;
            }
        }

        .modal__close-icon {
            background-color: #fff;
            border-radius: 50px;
            padding: 5px;
            margin-right: -10px;
            z-index: 1;
            position: relative;
        }
    }

    &--fullscreen {
        top: 0;
        border-radius: 0;
        height: 100%;
    }
}


/* css transition group */
.modal-fade-appear {
    opacity: 0;
}

.modal-fade-appear.modal-fade-appear-active,
.modal-fade-enter-done > .modal {
    opacity: 1;
    transition: opacity 250ms ease-out;
}

.modal-fade-close-exit {
    opacity: 0;
    transition: opacity 250ms ease-in;
}

.notification-backend {
    position: fixed;
    top: 80px;
    z-index: 100;
    background: #fff;
    left: 0;
    right: 0;
    max-width: 375px;
    margin: 0 auto;
    padding: 10px 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(159, 159, 159, 0.28);

    &__error {
        border: 1px solid $brand-danger;
        color: $brand-danger;
    }

    &__warning {
        border: 1px solid orange;
        color: orange;
    }
}
