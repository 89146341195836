@import '../../assets/styles/base/variables';
@import '../../assets/styles/base/mixins';

.aside-header-garage {
    &__name,
    &__logo {
        float: left;
    }

    &__name {
        width: 70%;

        @media (min-width: $screen-md-min) {
            width: calc(100% - 90px);
        }

        > h5 {
            font-size: 18px;
            font-family: $font-eina;
            font-weight: 500;
            margin: 0;
        }

        > p {
            font-size: 15px;
            margin: 0;
            color: #9b9b9b;
        }
    }

    &__logo {
        width: 30%;
        height: 42px;

        @media (min-width: $screen-md-min) {
            width: 90px;
        }

        > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.garage-container {
    padding: 15px 15px 80px;
    @include clearfix;

    @media (min-width: $screen-md-min) {
        padding-top: 70px;
    }

    @media (min-width: $screen-lg-min) {
        padding-top: 70px;
    }

    .form-error{
        position: static;
        text-align: left;
    }
}

.termin-picker {
    @media (min-width: $screen-lg-min) {
        width: 55%;
        float: left;
        @include clearfix;
    }
}

.dp-left-container {
    @media (min-width: $screen-lg-min) {
        width: 55%;
        float: left;
        @include clearfix;
        padding-left: 15px;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
        padding: 0 30px;
    }
}

.show-order-summery{
    @include clearfix;
    margin-bottom: 15px;
    font-weight: bold;

    a {
        font-size: $font-size;
        i {
            font-size: 10px;
            font-weight: bold;
        }
    }

    &__price{
        text-align: right;
        font-size: $font-size-2;
        color: $text-dark;
    }
}

.garage-container {
    .garage-item-container {
        margin: 0;
    }
    .garage-item-collapsed {
        padding: 10px 10px 20px 10px;
    }
}
