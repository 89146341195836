.tab {
    @include clearfix;
    background-color: $tab-background;
    padding: 15px 30px 30px 30px;
    box-sizing: border-box;
    box-shadow: 0 0 14px 0 rgba(159, 159, 159, 0.5);
    border-radius: 4px;
    max-width: 350px;

    &__items {
        @include clearfix;
        overflow-y: hidden;
        white-space: nowrap;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        text-align: center;
        box-shadow: 0 1px 0 0 #c4cdd5;
        display: table;
        table-layout: fixed;
        width: 100%;
        position: relative;

        &::-webkit-scrollbar {
            display: none;
        }

        > a {
            color: $tab-option-secondary;
            font-weight: 500;
            font-size: 13px;
            text-transform: uppercase;
            padding: 15px 15px;
            transition: none;
            display: table-cell;
            float: none;
            width: 100%;

            &.active {
                color: $brand-primary;
                border-bottom: 2px solid $brand-primary;
            }
        }
    }

    &__item-popup {
        white-space: initial !important;
        background: rgba(0, 0, 0, .75);
        position: absolute;
        top: calc(100% + 20px);
        border-radius: 4px;
        padding: 10px 10px;
        font-size: 14px;
        z-index: 1;

        &--left {
            left: -20px;
            right: calc(50% - 20px);
        }

        &--right {
            left: calc(50% - 20px);
            right: -20px;
        }

        p {
            color: #fff;
            font-size: 14px;
            margin: 0;
        }

        &:before {
            content: " ";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent rgba(0, 0, 0, .75) transparent;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: -10px;
        }
    }

    &__body {
        margin: 30px 0 0 0;

        .btn-lg {
            margin-bottom: 0;
        }
    }

    &__item-body:not(.active) {
        display: none;
    }

    &__item-body--active {
        @include clearfix;
        display: block !important;
    }
}

#loginCustomer p,
#loginGarage p{
    font-weight: 400;
}

#register.active {
    display: block;
}
