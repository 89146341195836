@import "../../../assets/styles/base/variables";

.service-toggler {
    position: relative;
    float: left;
    width: 100%;
    transition: .1s ease-in;
    
    @media (min-width: $screen-md-min) {
        position: relative;
    }
    
    &--additional {
        padding: 20px 0;

        @media (min-width: $screen-md-min) {
            padding: 30px 30px;

            .search-item-extra {
                right: 10px !important;
            }
        }
    
        .loader-service-check {
            top: 20px;
    
            @media (min-width: $screen-md-min) {
                top: 30px;
                left: 30px;
            }
        }
    }
    
    &--preselected {
        padding: 15px 15px;

        .loader-service-check {
            top: 15px;
            left: 15px;
        }
    }
    
    .input-control {
        padding-right: 40px;
    }
    
    .search-item-extra {
        position: absolute;
        right: 0;
        padding: 0;
        margin: 0;
    }
    
    &--disabled {
        cursor: default;
        pointer-events: none;
        opacity: .5;
    }

    &--hasHoverEffect {
        background-color: #fff !important;
        opacity: 1;
        pointer-events: initial;
        border-radius: 4px;
    }
}
