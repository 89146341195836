@import '../../../assets/styles/base/variables';

.alert {
  display: flex;
  padding: 10px 30px 10px 10px;
  margin: 15px 0;
  border-radius: 4px;
  position: relative;

  > p {
    color: inherit;
    margin: 0;
    word-break: break-word;
  }

  &__close-btn {
    position: absolute;
    top: 10px;
    font-size: 20px;
    right: 10px;
    cursor: pointer;
  }

  &--danger {
    background-color: $alert-danger-secondary;
    color: $alert-danger-primary;
    border: 1px solid $alert-danger-primary;
  }

  &--success {
    background-color: $alert-success-secondary;
    color: $alert-success-primary;
    border: 1px solid $alert-success-primary;
  }

  &--info {
  }

  &--warning {
  }

  &--fixed {
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: auto;
    max-width: 70%;
  }
}
