@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.btn {
    border: 0;
    padding: 8px 12px;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    outline: 0 !important;
    box-sizing: border-box;
    font-weight: 500;
    transition: 0.2s ease-in;
    cursor: pointer;

    &[disabled] {
        cursor: not-allowed;

        &:hover,
        &:active,
        &:focus {
            color: inherit;
            outline: 0 !important;
            background: $btn-disabled;
        }
    }

    &__icon {
        display: inline;
        margin-right: 5px;

        /* icon */
        > span {
            font-weight: bold;
            position: relative;
            top: 1px;
        }
    }

    /* sizes */
    &--md {
        font-size: 14px;
        font-weight: 700;
    }

    &--lg {
        font-size: 17px;
        padding: 15px 28px;
        width: 100%;
    }

    /* variations */
    &--primary {
        @include button-bg($btn-primary, $btn-secondary);
    }

    &--secondary {
        @include button-bg($btn-secondary, $btn-primary, $btn-primary);
    }

    &--terciary {
        background-color: #fff;
        color: $btn-terciary-primary;
        box-shadow: inset 0 0 0 1px $btn-terciary-secondary;
        font-weight: 400;
    }

    &--silver {
        background-color: #e6e6e6;
        padding: 10px 15px !important;
    }

    
    &--grayed-out {
        @include button-bg(#e6e6e6, #46515e);
        
        &:hover {
            background-color: #d0d0d0 !important;
        }
    }
    
    &--bluish {
        @include button-bg(#e8edf1, #46515e);
        padding: 15px 40px !important;
        font-size: 16px;
        
        &:hover {
            background-color: #e8edf1 !important;
        }
    }
    
    
    &--baloise-link {
        @include button-bg($button-ad-garage-secondary, $button-ad-garage-primary);

        &:not([disabled]):hover {
            background-color: $button-ad-garage-hover !important;
        }
    }

    &--garage-load-more {
        @include button-bg($button-garage-load-more-secondary, $button-garage-load-more-primary);

        &:not([disabled]):hover {
            background-color: $button-garage-load-more-hover !important;
        }
    }

    &--success {
        @include button-bg($label-success-secondary, $label-success-primary);

        &:not([disabled]):hover {
            background-color: $label-success-secondary;
        }

        [class^='icon'] {
            color: $label-success-lighten;
        }
    }

    &--warning {
        @include button-bg($label-warning-secondary, $label-warning-primary);

        &:not([disabled]):hover {
            background-color: $label-warning-secondary;
        }

        [class^='icon'] {
            color: $label-warning-lighten;
        }
    }

    &--info {
        @include button-bg($label-info-secondary, $label-info-primary);

        &:not([disabled]):hover {
            background-color: $label-info-secondary;
        }

        [class^='icon'] {
            color: $label-info-lighten;
        }
    }

    &--dark {
        @include button-bg($btn-dark, $label-dark-primary);

        &:not([disabled]):hover {
            background-color: $btn-dark-hover;
        }

        [class^='icon'] {
            color: $label-dark-primary;
        }
    }

    &--link {
        background: transparent;
        padding: 0;
        border: 0;
        outline: 0;
        font-weight: normal;
        margin: 0;
        font-size: 16px;
        line-height: 22px;
        transition: 0.2s ease-in;
        color: $blue;
    }

    &--round {
        border-radius: 50%;
    }
}
