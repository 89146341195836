@import "../../../assets/styles/base/variables";

.additional-service {
    margin-top: 35px;
    position: relative;
    padding: 5px 0;

    @media (min-width: $screen-md-min) {
        margin-top: 0;
        padding: 0;
        position: static;
    }

    &:before {
        content: " ";
        position: absolute;
        top: 0;
        height: 1px;
        background: #eaeaea;
        left: -30px;
        width: calc(100% + 55px);

        @media (min-width: $screen-md-min) {
            display: none;
        }
    }
}

button.additional-service-button {
    background-color: #e6e6e6;
    color: #46515e;

    @media (min-width: $screen-md-min) {
        display: none;
    }
}

.additional-service-uncheck-large button {
    margin-top: 20px;
    font-size: 14px;
    padding: 12px 32px !important;
}
