@import '../../../assets/styles/base/variables';

.input-control input[type='checkbox'] {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    // Box.
    & + label:before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 18px;
        height: 18px;
        background: white;
        border: 1px solid $brand-primary;
        border-radius: 4px;
    }

    // Box hover
    &:hover + label:before {
        background: #fff;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 4px;
        top: 9px;
        background: $brand-primary;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $brand-primary, 4px 0 0 $brand-primary, 4px -2px 0 $brand-primary,
            4px -4px 0 $brand-primary, 4px -6px 0 $brand-primary, 4px -8px 0 $brand-primary;
        transform: rotate(45deg);
    }
}
