@import '../base/variables';

.aside {
    width: 100%;
    background: #ffffff;
    border-right: 1px solid #d9d9d9;

    @media (min-width: $screen-md-min) {
        width: 375px;
        overflow-y: scroll;
        height: calc(100% - 45px);
        position: fixed;
        padding-bottom: 15px;
    }

    // Component Step
    > .step-indicator {
        padding: 30px 0 45px 0;
        margin:0;
        background: #f8f9fa;
    }
}


.aside-wrapper {
    background-color: #fff;
    float: left;
    width: 100%;
}

.aside-header,
.aside-accordion,
.aside-cta {
    float: left;
    width: 100%;
}

.aside-header {
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
    padding: 25px 25px;

    &__title {
        p {
            color: $text-light;
        }

        &--small-medium {
            margin: 0;
            font-size: 18px;
            font-family: $font-eina;
            font-weight: 500;
        }

        &--small-medium + p {
            margin: 0;
            font-size: 15px;
        }
    }
}

.aside-cta {
    padding: 25px 25px;
    background-color: #fff;

    @media (min-width: $screen-md-min) {
        display: none;
        border-right: 1px solid #d9d9d9;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 375px;
    }
}
