@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.price {
    margin: 20px 0;
    color: #919eab;
    font-size: 15px;
    font-weight: 700;
    text-align: left;

    @media (min-width: $screen-lg-min) {
        margin: 0 30px 0 5px;
        text-align: right;
    }

    .discount, .new {
        font-size: 15px;
    }

    .old, .discount {
        position: relative;
        top: 5px;
    }

    .old, .new, .discount {
        @media (min-width: $screen-lg-min) {
            font-size: 15px;
        }
    }
    .old {
        font-size: 15px;
        color: #cccccc;
        text-decoration: line-through;
        margin-left: 3px;
    }

    .discount {
        color: $brand-primary;
    }

    .new {
        width: 100%;
        display: inline-block;
        color: #606060;
        line-height: 30px;
    }

    .icon {
        top: calc(50% - 2px);
        position: absolute;
        right: 0;
        transform: translateY(-50%);
    }
}
