//Base
$blue: #008dff;
$green: #1c9249;
$red: #dd122c;

//Colors
$brand-primary: #008dff;
$brand-success: #1c9249;
$brand-black: #292929;
$brand-danger: #dd122c;
$brand-info: #3a85cb;
$brand-warning: #ff7928;
$bg-color: #F8F9FA;

$blue-lighten: lighten($brand-primary, 45%);
$red-lighten: lighten($brand-danger, 35%);
$green-lighten: lighten($brand-success, 40%);

$secondary-gray: lighten(black, 60.78%);
$background-gray: lighten(black, 97.25%);
$border-color: lighten(black, 80%);
$label-gray: lighten(black, 29.02%);

// Buttons
$btn-primary: $brand-primary;
$btn-primary-hover: lighten($brand-primary, 10%);
$btn-primary-light: lighten($btn-primary, 45%);
$btn-secondary: #fff;
$btn-disabled: rgba(0, 0, 0, 0.3);
$btn-line-height: 19px;
$btn-border-color: $brand-primary;
$btn-success: #2ecc71;
$btn-success-hover: lighten($btn-success, 10%);
$btn-dark: rgba(0, 0, 0, 0.13);
$btn-dark-hover: lighten($btn-dark, 20%);
$btn-black: #000;
$btn-black-hover: lighten($btn-black, 10%);
$grayish: #7f91a8;

// Alerts
$alert-success-primary: #2ecc71;
$alert-success-secondary: #cbf3dc;
$alert-danger-primary: #e74c3c;
$alert-danger-secondary: #fbeae5;

// Nav
$nav-default: transparent;
$nav-filled: #fff;
$nav-text: #000;

// Tab
$tab-background: #fff;
$tab-option-primary: $brand-primary;
$tab-option-secondary: #c4cdd5;

// Accordion
$accordion-primary: #fff;
$accordion-border: #f8f8f8;
$accordion-icon: #E0D9E6;

// Typography
$text-color: $brand-black;
$text-lighter: #808080;
$text-gray: #637381;
$line-height: 1.42857143;

$font-size: 14px;
$font-size-2: 16px;
$font-size-4: 18px;
$font-size-6: 20px;

$font-path:         '/web/fonts/';

$font-default:      'Atto', Helvetica, Arial, sans-serif;
$font-eina:         'Eina', sans-serif;
$font-atto:         'Atto', sans-serif;
$font-icon:         'carhelper', sans-serif;

$line-height-base: 1.428571429 !default; // 20/14
$line-height-computed: floor(($font-size * $line-height-base)) !default; // ~20px


// Breakpoints
$screen-lg: 1140px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 400px;
$screen-smaller-xs: 375px;


// Grid
$grid-columns: 8;
$padding-grid-columns: 20px;
// Grid Breakpoints
$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;

$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;

$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;

$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
// Grid container-size
$container-tablet:             (720px + $padding-grid-columns) !default;
$container-sm:                 $container-tablet !default;

$container-desktop:            (940px + $padding-grid-columns) !default;
$container-md:                 $container-desktop !default;

$container-large-desktop:      (1140px + $padding-grid-columns) !default;
$container-lg:                 $container-large-desktop !default;




// Form
$input-border-focus:             #66afe9 !default;
$cursor-disabled:                not-allowed !default;
$form-input-disabled:            #dfe3e8;
//** `.form-group` margin
$form-group-margin-bottom:       15px !default;
$border-radius: 2px;
$border-radius-2: 4px;
$form-padding: 10px;
$form-margin: 10px 0;


// Tooltip
$tooltip-max-width: 150px !default;
$tooltip-bg: rgba(0, 0, 0, 60%) !default;
$tooltip-arrow: 5px !default;

// Border
$border: 1px solid $border-color;


// Buttons
$padding-base-vertical: 10px;
$padding-base-horizontal: 10px;

$padding-sm-horizontal: 28px;
$padding-sm-vertical: 15px;

$padding-xs-horizontal: 22px;
$padding-xs-vertical: 8px;

// Anchors
$link-color: darken($brand-primary, 10%);
$link-active-color: darken($brand-primary, 10%);

// Pages
$home-light: #f8f8f8;
$home-dark: rgba(0, 0, 0, .9);
$home-start-now-background: #f6fffa;
$home-start-now-title: #2ecc71;
$home-default: #fff;

$color-internet-explorer: #40bdef;

// Slick Carousel
$slick-loader-path: '/web/images/vendor/slick-carousel/slick/';

// JQuery UI
$jquery-ui-image-path: '/web/images/vendor/jquery-ui/themes/base/';

// REPAIR SHOP

// Instant Offer Request
$instant-offer-item-price-background: lighten($brand-success, 63%);
$light-border-color: lighten($border-color, 5%);
$lighter-border-color: lighten($border-color, 15%);

