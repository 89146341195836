@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.garage-load-progress-bar {
  margin-bottom: -15px;

  .progress-bar{
    width: 100%;
    height: 6px;
  }
  .progress-bar-label{
    background-color: #378eff;
    height: 100%;
    width: 20%;
    border-radius: 5px;
    transition: width 0.1s ease-out;
  }
  .progress-bar-description{
    font-family: $font-eina;
    font-weight: 600;
    color: $text-lighter;
    margin-bottom: 10px;
  }
  .progress-bar-description img{
    width: 20px;
    vertical-align: bottom;
  }

  @media (max-width: $screen-md-min) {
    z-index: 99;
    width: 100%;
    padding: 10px 0;
    left: 0;
    position: fixed;
    top: 48px;
    background: #fff;

    .progress-bar-description {
        padding: 0 10px;
    }
  }
}
