@import '../../../assets/styles/base/mixins';
@import '../../../assets/styles/base/variables';

.checkout-detail {
    float: left;
    width: 100%;
    margin: 25px 0 10px;
    @include clearfix;
}

.checkout-item-title{
    margin-bottom: 5px;

    > p{
        padding: 0 20px;
        font-size: 12px;
        color: $text-light-dark;
        margin: 0;
    }
}

.checkout-item {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 5px;

    @include clearfix;

    > p {
        font-weight: 600;
        margin: 0;
        font-size: 13px;
        color: $text-dark;

    }

    &__name {
        width: 35%;
        color: $text-dark;
    }

    &__price {
        width: 65%;
        text-align: right;
        color: $text-light-dark;

        &--garage-discount{
            color: $btn-success;
        }
    }

    &__name,
    &__price {
        float: left;

        > p {
            font-family: $font-eina;
            font-weight: 500;
            font-size: $font-size;
            margin: 0;
        }
    }

    &--sub {
        padding-left: 20px;
        margin-bottom: 5px;
        color: $text-dark;
    }

    &--sum {
        &__price {
            color: $text-dark;
        }
    }

    &--border-and-margin {
        margin-top: 15px;
        border-top: 2px solid #f5f5f5;
        padding-top: 15px;
    }
}

.checkout-item-children-container {
    margin: 7px 0;
    float: left;
    @include clearfix;
}

.checkout-item-child {
    width: 100%;
    float: left;
    @include clearfix;
    opacity: 0.4;

    > p {
        font-weight: 500;
        margin: 0;
        font-size: 13px;
        line-height: 1.3;
    }
}

.checkout-vehicle{
    padding: 0 20px;
    margin-bottom: 20px;

    &__make{
        font-weight: 600;
        color: $text-dark;
        font-size: $font-size;
    }

    &__total-price{
        font-weight: 600;
        font-size: $font-size;
        color: $text-light-dark;
        text-align: right;
    }
}
.checkout-voucher-input{
    margin: 0;

    input{
        height: 28px;
        min-height: 28px;
        font-size: 12px;

        &::placeholder{
            font-size: 12px;
        }
    }
}
.checkout-voucher-btn{
    width: 45px;
    max-width: 100%;
    height: 28px;
    padding: 0 !important;
    margin-top: 5px;
    margin-bottom: 0;

    > i{
        font-weight: bold;
        display: block;
        font-size: $font-size-2;
    }
}