@import "../base/variables";

.circle{fill:none;stroke: $btn-success-hover;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;}
.circle-dash{fill:none;stroke:$btn-success-hover;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;}
.check{fill:none;stroke:$btn-success-hover;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.check-dash{fill:none;stroke:$btn-success-hover;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}

.check {
    stroke-dasharray: 60 100;
    animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s;
    -webkit-animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s;
    -moz-animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s;
    -o-animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s;
    opacity: 0;
}

@-webkit-keyframes check {
    from {stroke-dashoffset: 60;
        opacity: 1;}

    to {stroke-dashoffset: 00;
        opacity: 1;}
}

@-moz-keyframes check {
    from {stroke-dashoffset: 60;
        opacity: 1;}

    to {stroke-dashoffset: 00;
        opacity: 1;}
}

@keyframes check {
    from {stroke-dashoffset: 60;
        opacity: 1;}

    to {stroke-dashoffset: 00;
        opacity: 1;}
}

.check-dash {
    stroke-dasharray: 10 100;
    animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards;
    -webkit-animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards;
    -moz-animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards;
    -o-animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards;
}

@-webkit-keyframes check-dash {
    from {stroke-dashoffset: 120;}
    to {stroke-dashoffset: 45;}
}

@-moz-keyframes check-dash {
    from {stroke-dashoffset: 120;}
    to {stroke-dashoffset: 45;}
}

@keyframes check-dash {
    from {stroke-dashoffset: 120;}
    to {stroke-dashoffset: 45;}
}

.circle {
    stroke-dasharray: 300 300;
    animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s;
    -webkit-animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s;
    -moz-animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s;
    -o-animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s;
    opacity: 0;
}

@-webkit-keyframes circle {
    from {stroke-dashoffset:300;
        opacity: 1;}
    to {stroke-dashoffset:0;
        opacity: 1;}
}

@-moz-keyframes circle {
    from {stroke-dashoffset:300;
        opacity: 1;}
    to {stroke-dashoffset:0;
        opacity: 1;}
}

@keyframes circle {
    from {stroke-dashoffset:300;
        opacity: 1;}
    to {stroke-dashoffset:0;
        opacity: 1;}
}

.circle-dash {
    stroke-dasharray: 10 300;
    animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
    -webkit-animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
    -moz-animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
    -o-animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
    opacity: 0;
}

@-webkit-keyframes circledash {
    from {stroke-dashoffset:320;
        opacity: 1;}
    to {stroke-dashoffset: 20;
        opacity: 1;}
}

@-moz-keyframes circledash {
    from {stroke-dashoffset:320;
        opacity: 1;}
    to {stroke-dashoffset: 20;
        opacity: 1;}
}

@keyframes circledash {
    from {stroke-dashoffset:320;
        opacity: 1;}
    to {stroke-dashoffset: 20;
        opacity: 1;}
}
