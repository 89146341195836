@import "../../../../assets/styles/base/variables";

.additional-service-filter {
    > p {
        margin: 0;
    }

    &--active {
        @media (max-width: $screen-md-min) {
            position: fixed;
            top: -4px;
            left: 10px;
            width: calc(100% - 65px);
            z-index: 1;

            .input-control {
                background-color: #fff;
                padding: 7px 0 0 0;
                top: 1px;
                margin: 4px 0;
            }
        }
    }
}
