@import '../../../assets/styles/base/mixins';
@import '../../../assets/styles/base/variables';

.placeholder-official-service-checkbox {
    width: 15px !important;
    height: 15px !important;
    margin-top: 7px;
    float: left;
}

.placeholder-official-service-title {
    width: calc(100% - 25px) !important;
    height: 30px !important;
    float: left;
    margin-right: 0 !important;
}
