h1, h2, h3, h4, h5, h6 {
    &.dark {
        color: $text-color;
    }

    &.light {
        color: $text-lighter;
    }

    > a {
        color: inherit;
        font-size: inherit;
    }
}

h1 {
    margin: 0 0 15px 0;
    font-family: $font-eina;
    font-weight: 700;
    font-size: 36px;

    @media (min-width: $screen-md-min) {
        font-size: 54px;
    }
}

h2 {
    font-family: $font-eina;
    font-weight: 700;
    font-size: 40px;
    //line-height: 46px;
    
    &.wilkommen {
        font-size: 36px;
    }
    
    &.key-numbers {
        font-size: 40px;
        line-height: 1.1;
    }
}

h3 {
    font-family: $font-eina;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
}

h4 {
    font-size: 24px;
    line-height: 34px;
    
    &.black-ber {
        margin: 15px 0 30px 0;
        font-family: $font-eina;
        font-weight: 700;
        font-size: 22px;
        line-height: 1.09;
    }
}

h5 {
    font-family: $font-eina;
    font-weight: 700;
    line-height: 22px;
    font-size: 22px;
}

h6 {
    font-weight: 300;
    font-size: 22px;
    line-height: 29px;
}

p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    
    &.light {
        color: #9b9b9b;
    }
}

.menu-element {
    font-family: $font-eina;
    font-size: 22px;
    line-height: 1;
    color: $text-color;
}

.text {
    font-size: 16px;
    line-height: 22px;
    color: #9b9b9b;

    &--dark {
        color: #000;
    }
}

.text-strong {
    @extend .text;
    color: #000;
    font-weight: 700;
}


.text-regular-15 {
    font-size: 15px;
}

.text-regular-15 > .text-strong {
    margin-bottom: 5px;
    display: inline-block;
}
