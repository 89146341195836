
// Base

@mixin clearfix() {
  &:after {
    content: '';
    clear: both;
    display: block;
  }
}

@mixin hoverActiveFocusNone() {
  &:focus,
  &:active,
  &:hover {
    color: inherit;
    text-decoration: none;
    outline: 0;
  }
}

@mixin linkDefaultText() {
  color: $text-color;
  @include hoverActiveFocusNone();
}

@mixin noneHighlight() {
  user-select: none;
}

@mixin infRotate() {
  animation: rotating 2s linear infinite;
}

@mixin transition($transition...) {
  transition: $transition;
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
  box-shadow: $shadow;
}



// Form

@mixin boxShadow() {
  box-shadow: -1px 0px 38px -1px rgba(87, 87, 87, 0.5);
}

@mixin form-control-focus($color: $input-border-focus) {
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &:focus {
    border-color: $color;
    outline: 0;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
  }
}

@mixin input-size($parent, $input-height, $padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  #{$parent} {
    height: $input-height;
    padding: $padding-vertical $padding-horizontal;
    font-size: $font-size;
    line-height: $line-height;
    border-radius: $border-radius;
  }

  select#{$parent} {
    height: $input-height;
    line-height: $input-height;
  }

  textarea#{$parent},
  select[multiple]#{$parent} {
    height: auto;
  }
}



// Alerts

@mixin alert-variant($background-color, $border-color, $text-color) {
  background-color: $background-color;
  border-color: $border-color;
  color: $text-color;

  hr {
    border-top-color: darken($border-color, 5%);
  }
  .alert-link {
    color: darken($text-color, 10%);
  }
}



// Tooltips

@mixin reset-text() {
  font-family: $font-default;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: $line-height-base;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
}



// Buttons

@mixin tab-focus() {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

@mixin button-variant($text-color, $background-color, $border-color) {
  color: $text-color;
  background-color: $background-color;
  border-color: $border-color;

  &:focus,
  &.focus {
    color: $text-color;
    background-color: darken($background-color, 10%);
    border-color: darken($border-color, 25%);
  }
  &:hover {
    color: $text-color;
    background-color: darken($background-color, 10%);
    border-color: darken($border-color, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $text-color;
    background-color: darken($background-color, 10%);
    border-color: darken($border-color, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $text-color;
      background-color: darken($background-color, 17%);
      border-color: darken($border-color, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background-color;
      border-color: $border-color;
    }
  }

  .badge {
    color: $background-color;
    background-color: $text-color;
  }
}

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}




// Grid

// Grid generator function
@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left:  ceil(($padding-grid-columns / 2));
    padding-right: floor(($padding-grid-columns / 2));
  }
}

@mixin float-grid-columns($class, $i: 1, $list: ".col-#{$class}-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-#{$class}-#{$i}";
  }
  #{$list} {
    float: left;
  }
}


@mixin calc-grid-column($index, $class, $type) {
  @if ($type == width) and ($index > 0) {
    .col-#{$class}-#{$index} {
      width: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) and ($index > 0) {
    .col-#{$class}-push-#{$index} {
      left: percentage(($index / $grid-columns));
    }
  }
  @if ($type == push) and ($index == 0) {
    .col-#{$class}-push-0 {
      left: auto;
    }
  }
  @if ($type == pull) and ($index > 0) {
    .col-#{$class}-pull-#{$index} {
      right: percentage(($index / $grid-columns));
    }
  }
  @if ($type == pull) and ($index == 0) {
    .col-#{$class}-pull-0 {
      right: auto;
    }
  }
  @if ($type == offset) {
    .col-#{$class}-offset-#{$index} {
      margin-left: percentage(($index / $grid-columns));
    }
  }
}

@mixin loop-grid-columns($columns, $class, $type) {
  @for $i from 0 through $columns {
    @include calc-grid-column($i, $class, $type);
  }
}

@mixin make-grid($class) {
  @include float-grid-columns($class);
  @include loop-grid-columns($grid-columns, $class, width);
  @include loop-grid-columns($grid-columns, $class, pull);
  @include loop-grid-columns($grid-columns, $class, push);
  @include loop-grid-columns($grid-columns, $class, offset);
}

@mixin container-fixed($gutter: $padding-grid-columns) {
  margin-right: auto;
  margin-left: auto;
  padding-left:  floor(($gutter / 2));
  padding-right: ceil(($gutter / 2));
  @include clearfix;
}

@mixin make-row($gutter: $padding-grid-columns) {
  margin-left:  ceil(($gutter / -2));
  margin-right: floor(($gutter / -2));
  @include clearfix;
}

@mixin make-xs-column($columns, $gutter: $padding-grid-columns) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);
}
@mixin make-xs-column-offset($columns) {
  margin-left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-push($columns) {
  left: percentage(($columns / $grid-columns));
}
@mixin make-xs-column-pull($columns) {
  right: percentage(($columns / $grid-columns));
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $padding-grid-columns) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-sm-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-offset($columns) {
  @media (min-width: $screen-sm-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-push($columns) {
  @media (min-width: $screen-sm-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-sm-column-pull($columns) {
  @media (min-width: $screen-sm-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $padding-grid-columns) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-md-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-offset($columns) {
  @media (min-width: $screen-md-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-push($columns) {
  @media (min-width: $screen-md-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-md-column-pull($columns) {
  @media (min-width: $screen-md-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $padding-grid-columns) {
  position: relative;
  min-height: 1px;
  padding-left:  ($gutter / 2);
  padding-right: ($gutter / 2);

  @media (min-width: $screen-lg-min) {
    float: left;
    width: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-offset($columns) {
  @media (min-width: $screen-lg-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-push($columns) {
  @media (min-width: $screen-lg-min) {
    left: percentage(($columns / $grid-columns));
  }
}
@mixin make-lg-column-pull($columns) {
  @media (min-width: $screen-lg-min) {
    right: percentage(($columns / $grid-columns));
  }
}

@mixin valign() {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin button-bg ($bg-color, $color, $outline-color: 'none') {
    $bg-color-hover: lighten($bg-color, 10%);
    background-color: $bg-color;
    color: $color;

    // if outline color is defined & is not default (none)
    @if ($outline-color != 'none') {
        box-shadow: inset 0px 0px 0px 2px $outline-color;
    }

    &:active, &:focus {
        color: $color;
    }

    &[disabled] {
        box-shadow: none;
        background-color: $btn-disabled;
        color: #000 !important;

        // if outline color is defined & is not default (none)
        @if ($outline-color != 'none') {
            box-shadow: inset 0px 0px 0px 2px $btn-disabled;
        }
    }

    &:not([disabled]):hover {
        background-color: $bg-color-hover;
        color: $color;

        // if outline color is defined & is not default (none)
        @if ($outline-color != 'none') {
            box-shadow: inset 0px 0px 0px 2px $outline-color;
            color: $bg-color;
            background-color: $color;
        }
    }
}
