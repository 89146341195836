// plugin
@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.accordion {
    @media (min-width: $screen-md-min) {
        padding-bottom: 45px;
    }
}

.accordion__item {
    @include clearfix;
    border-bottom: 1px solid #d9d9d9;

    &:last-child {
        border-bottom: 0;
    }
    
    &--no-border {
        @media (max-width: $screen-md-min) {
            border-bottom: 0;
        }
    }
    
    &--white-on-mobile {
        @media (max-width: $screen-md-min) {
            background-color: #fff;
        }
    }
}

.u-position-absolute {
    position: absolute;
}

.u-position-relative {
    position: relative;
}

.accordion__item--has-icon {
    position: relative;
}

.accordion__arrow {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -25px;
    margin-top: -10px;
    color: #000;
    transform: rotate(-180deg);
    font-size: 12px;
    top: 11px;
}

[aria-expanded='true'] .accordion__arrow,
[aria-selected='true'] .accordion__arrow {
    transform: rotate(-180deg);
}

[aria-expanded='true'] .accordion__arrow,
[aria-selected='true'] .accordion__arrow {
    transform: rotate(0deg);
}

.accordion__arrow,
.accordion__arrow {
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

.accordion__title {
    padding: 20px 25px;
    cursor: pointer;
    outline: none;
    
    @media (min-width: $screen-md-min) {
        padding: 20px 25px 20px 0;
    }

    > h5 {
        display: inline;
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        
        @media (min-width: $screen-md-min) {
            font-size: 16px;
        }
        
    }
}

.accordion__body {
    padding: 0 25px 25px 25px;
    @include clearfix;
    
    @media (min-width: $screen-md-min) {
        padding: 0 0 25px 0;
    }
}
