input {
    &.has-left-icon {
        padding-left: 42px;
    }

    &.has-right-icon {
        cursor: pointer;
        padding-right: 42px;
    }
}

.input-wrapper {
    position: relative;
}

.input-icon {
    top: 0;
    position: absolute;
    height: 42px;
    width: 42px;
    text-align: center;
    opacity: 0.75;

    &--left {
        left: 0;
    }

    &--right {
        cursor: pointer;
        right: 0;
    }

    > span {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 18px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
}

.form-error {
    display: inline-block;
    width: 100%;
    position: absolute;
    text-align: right;

    > p {
        margin: 0;
        font-size: 12px;
        color: red;
    }
}
