@import './alert-danger';
@import './alert-success';

.alert {
  width: 100%;
  min-height: 44px;
  height: auto;
  border-radius: 2px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  padding: 13px 58px 13px 13px;
  font-size: 14px;
  box-sizing: border-box;
  margin: 15px 0;
}

.alert-btn-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 43px;
  width: 43px;
  text-align: center;

  &__icon {
    font-weight: bold;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
}
