.animation-check-wrapper {
    text-align: center;

    svg {
        max-width: 100px;
        max-height: 100px;
        margin-bottom: 25px;
    }
}


