@import '../../assets/styles/base/mixins';
@import '../../assets/styles/base/variables';

.garage-list-container {
    float: left;
    margin: 15px 0;
    width: 100%;
    @include clearfix;

    &--extra-padding {
        padding: 100px;
        text-align: center;
        opacity: 0.4;
    }

    &--has-predefined-garage {
        margin: 0;
    }
}

.predefined-garage-title {
    font-size: 12px;
    color: $text-lighter;
    font-weight: bold;
    text-transform: uppercase;
}

.garage-list-options {
    float: left;
    width: 100%;
    padding: 0 10px;
    @include clearfix;

    @media (min-width: $screen-md-min) {
        padding: 0;
    }

    &__item {
        width: 50%;

        &--left {
            float: left;

            @media (min-width: $screen-lg-min) {
                width: 80%;
            }

            > button {
                margin: 0;
            }
        }

        &--right {
            float: right;
            font-size: 14px;
            color: #637381;

            i {
                color: #637381;
            }

            @media (min-width: $screen-lg-min) {
                width: 20%;
            }
        }

        > p {
            line-height: 44px;
            margin: 0;
            font-family: $font-eina;
            font-weight: 500;
        }


        // select component
        .dd-select {
            font-weight: 700;
        }

        .dd-container {
            margin: 0;
            text-align: right;
        }

        .dd-list__options--item {
            text-align: left;
        }
    }
}

.garage-list {
    float: left;
    width: 100%;
    margin-bottom: 30px;
    @include clearfix;

    @media (min-width: $screen-md-min) {
        padding: 0;
    }

    @media (min-width: $screen-lg-min) {
        padding: 0;
    }

    &__load-more {
        width: 100%;
        float: left;
        text-align: center;
        @include clearfix;
    }
    
    &--margin-bottom-10 {
        margin-bottom: 10px;
    }
}

.garage-list-discount {
    padding: 15px 20px;
    font-size: 16px;

    &__headline {
        font-weight: 600;
        padding-left: 35px;
        position: relative;
        color: $text-dark;

        i {
            position: absolute;
            font-size: 22px;
            left: -5px;
            color: $btn-success;
            top: 2px;
        }
    }

    &__label {
        font-weight: 600;
        color: $text-light-dark;
        padding-left: 35px;
    }

    &__value {
        text-align: center;
        font-weight: 600;
        color: $btn-success;
        font-size: 26px;
        line-height: 40px;
    }

    @media (max-width: $screen-md-min) {
        font-size: 14px;

        i {
            font-size: 17px;
            top: 2px;
        }

        &__value {
            font-size: 17px;
        }
    }

    &--checkout{
        font-size: 12px;
        padding: 0 0 0 5px;

        .icon-check-circle-full{
            font-size: 18px;
        }

        &__headline {
            padding-left: 25px;
        }
        &__label {
            padding-left: 25px;
        }
        &__value {
            font-size: 18px;
            text-align: right;
            line-height: normal;
        }

        @media (max-width: $screen-lg-min) {
            font-size: 12px;
            padding: 0;
        }
    }
}