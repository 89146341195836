@import '../../../assets/styles/main/base/variables';

.dd-container {
    margin: 10px 0;
    position: relative;
    outline: 0;
    float: left;
    width: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block !important;
}

.dd-title {
    margin-bottom: 5px;

    > label {
        font-weight: 500;
        margin: 0;
    }
}

.dd-header {
    position: relative;

    &__icon {
        cursor: pointer;
        position: absolute;
        right: 15px;
        transform: translateY(-50%);
        top: 50%;
    }
}

.dd-select {
    position: relative;

    font-size: 16px;
    display: block;
    width: 100%;
    border-radius: $border-radius-2;
    background-color: #ffffff;
    border: 1px solid $border-color;
    outline: 0;
    cursor: pointer;
    box-sizing: border-box;

    > .input-control {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
    }

    > .input-control > .input-wrapper > input {
        color: #606060;
        font-weight: 400;
        margin: 0;
        border: 0;

        &:focus {
            border: 0 !important;
        }
    }

    &--opened {
        border-radius: 4px 4px 0 0;
        border-bottom: 0;
        height: 44px;
    }

    &--error + .dd-header__icon {
        color: $brand-danger;
    }

    &--disabled {
        background-color: $form-input-disabled;
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }

    &--error {
        opacity: 1;
        border: 1px solid #e74c3c;
        background-color: #fbeae5;
    }

    &--single-item {
        cursor: default;
    }

    &--transparent {
        background-color: transparent;
        border: none;
    }

    &[readonly] {
        cursor: default;
    }

    &:not(.input-error):focus {
        border: 1px solid $brand-primary;
    }

    &::placeholder {
        color: $secondary-gray;
    }
}

.dd-select-trigger-filter {
    padding: 10px 40px 10px 10px;

    &--placeholder {
        color: #9a9b9a;
    }
}

.dd-list {
    background-color: #fff;
    border: 1px solid #cccccc;
    max-height: 170px;
    overflow: auto;
    width: 100%;
    position: absolute;
    border-radius: 0 0 4px 4px;
    border-top: 0;
    z-index: 2;
    top: 65px;

    small {
        font-size: 75%;
    }

    &__options {
        list-style-type: none;
        padding: 0;
        margin: 0;

        &--item {
            cursor: pointer;
            padding: 10px 10px;
            font-size: 16px;
            color: #606060;

            &:nth-child(odd) {
                background-color: rgba(107, 126, 172, 0.05);
            }

            &:hover,
            &.selected {
                background-color: $btn-primary-hover;
                color: #fff;
            }

            &.highlighted {
                background-color: $btn-primary-hover;
                color: #fff;
            }
        }
    }
}

#make_id .dd-list {
    text-transform: uppercase;
}

.dd-no-result {
    padding: 10px 10px;
    color: #606060;
    border-top: 1px solid #cccccc;

    > p {
        margin: 0;
    }
}

#city .dd-select-trigger-filter {
    white-space: nowrap;
    overflow-x: hidden !important;
    text-overflow: ellipsis;
    padding: 10px;
}
