@import '../main/base/variables';

.w-box{
  border-radius: 4px;
  box-shadow: 0 2px 37px -11px rgba(210, 210, 210, 0.5), 0 2px 5px -1px rgba(171, 171, 171, 0.5);
  background-color: #FFF;
  margin-bottom: 20px;

  @include clearfix;

}

.w-box-header{
  padding: 15px 20px;

  &__title{
    font-size: $font-size-2;
    margin: 0;
    line-height: normal;
  }
}