.btn {
    border: 0;
    margin: 15px 0;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    outline: 0 !important;
    box-sizing: border-box;
    font-weight: 500;
    transition: 0.2s ease-in;

    &--rounded {
        border-radius: 50px;
    }

    &[disabled] {
        cursor: not-allowed;

        &:hover,
        &:active,
        &:focus {
            outline: 0 !important;
            background: $btn-disabled;
        }
    }
}

.btn-primary {
    @include button-bg($btn-primary, $btn-secondary);
}

.btn-secondary {
    @include button-bg($btn-secondary, $btn-primary, $btn-primary);
}

.btn-black {
    @include button-bg($btn-black, $btn-secondary);
}

.btn-success {
    @include button-bg($btn-success, $btn-secondary);
}

.btn-grayed-out {
    @include button-bg(#e6e6e6, #46515e);
}

.btn-bluish {
    @include button-bg(#e8edf1, #46515e);
    padding: 15px 40px !important;
    font-size: 16px;

    &:hover {
        background-color: #e8edf1 !important;
    }
}

.btn-transparent {
    @include button-bg(transparent, #000);
}

.btn-transparent-info {
    @include button-bg(transparent, $brand-primary);
}

.btn-transparent-danger {
    @include button-bg(transparent, $brand-danger);
}

.btn-gray {
    @include button-bg(#c3c3c3, $btn-secondary);
}

.btn-transparent--outline-white {
    @include button-bg(transparent, #fff, #fff);

    &:not([disabled]):hover {
        color: #000 !important;
    }
}

//.btn-facebook {
//    @include button-bg(#415daf, #fff);
//}
//
//.btn-google {
//    @include button-bg(#e74c3c, #fff);
//}

a.btn-google,
a.btn-facebook {
    font-size: 14px;
    color: gray;
    padding: 10px 10px !important;
    width: 90%;
    border: 1px solid #cecece;
}

a.btn-google {
    > img {
        margin-right: 5px;
    }
}

.btn-primary-light {
    @include button-bg($btn-primary-light, #000);

    &:not([disabled]):hover {
        background-color: $btn-primary-hover;
        color: $btn-secondary;
    }
}

.btn-sm {
    font-size: 12px;
    padding: 10px 17px !important;
}

.btn-lg {
    font-size: 17px;
    padding: 15px 28px !important;
}
.btn-md {
    font-size: 17px;
    padding: 15px 20px !important;
}

.btn-lg {
    width: 100%;

    &--auto {
        @media (min-width: $screen-md-min) {
            width: auto;
        }
    }
}

.btn-dark {
    background-color: #000;
}
