@import '../../../assets/styles/base/variables';

.modal-service {
    &__description {
        color: $text-light;
        margin-bottom: 25px;
    }

    &__list {
        margin-bottom: 25px;
    }

    &__sibling-item {
        margin: 0;
    }
}
