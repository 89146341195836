@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.react-datepicker__input-container {
    select,
    input[type="text"],
    input[readonly],
    select::placeholder,
    input[type="text"]::placeholder,
    input[readonly]::placeholder,
    textarea::placeholder {
        font-size: 12px;
        padding-bottom: 11px;
    }
}

.sdp {
    padding: 0;
    
    label {
        float: left;
        width: 40%;
        text-align: right;
        margin-top: 20px;
        padding-right: 10px;
        
        > p {
            color: #7f91a8;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;

            @media (min-width: $screen-md-min) {
                font-size: 10px;
            }
        }
    }

    label[for=date] {
        margin-top: 27px;
    }
    
    label[for=time] {
        margin-top: 27px;
    }
    
    input {
        cursor: pointer;
        box-shadow: 0 11px 14px -10px #e5eced;
        border: solid 1px #e5eced;
    }
    
    .field {
        float: left;
        width: 60%;
        position: relative;
    }
    
    button[type=submit] {
        font-weight: 500;
        font-size: 15px;
        margin: 10px 0;
        width: 100%;
    }

    .dp-errors{
        float: left;
        margin: 0;
        text-align: center;
        width: 100%;
        > p {
            color: $alert-danger-primary;
            font-size: 14px;
        }
    }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%;
}

.sdp-date,
.sdp-availability {
    float: left;
    width: 100%;

    &--horizontal{
        label {
            > p {
                font-size: 10px;
            }
        }

        @media (min-width: $screen-md-min) {
            width: 50%;
        }

        @media (max-width: $screen-sm-min) {
            label{
                width: 30%;
            }
            .field{
                width: 70%;
            }
        }
    }
}

.sdp-time {
    margin: 10px 0;
    padding: 12px 10px;
    background-color: #fff;
    width: 50%;
    float: left;
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    box-shadow: 0 11px 14px -10px #e5eced;
    border: solid 1px #e5eced;

    &__title {
        color: #3c5063;
        font-weight: 700;
        font-size: 12px;
        display: block;
        width: 100%;

        @media (min-width: $screen-md-min) {
            font-size: 10px;
        }
    }

    &__text {
        color: #7f91a8;
        font-size: 10px;
        display: block;
        width: 100%;
    }

    &.active {
        background-color: $btn-primary;
        color: #fff;

        > span {
            color: #fff;
        }
    }

    &--left {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &--right {
        border-left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
.react-datepicker {
    .react-datepicker__navigation {
        text-indent: unset;
    }
}

button.react-datepicker__navigation--previous, button.react-datepicker__navigation--next {
    height: 35px;
    width: 35px;
    border: none;
    padding: 0;
    top: 24px;
    font-family: carhelper !important;
    line-height: 1;

    &:before {
        content: '\e940';
        padding: 10px;
        display: inline-block;
        color: #959595;
        font-size: 15px;
        font-weight: bold;
    }
}

button.react-datepicker__navigation--next {
    right: 7%;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

button.react-datepicker__navigation--previous {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    left: 7%;
}