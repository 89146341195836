@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.official-service-container {
    @media (min-width: $screen-md-min) {
        margin: 0 0 20px 0;
    }

    input[type=checkbox] + label {
        cursor: default;
        padding-right: 40px;
    }
}

.official-service-header .title-service {
    margin-top: -5px;
}


.input-control #official-service-checkbox:checked + label:after {
    top: 8px;
}
