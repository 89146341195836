@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.illustration-card {
    padding: 15px 15px;
    background-color: #fff;
    border-radius: 4px;
    float: left;
    width: 100%;
    box-shadow: 0 2px 37px -11px rgba(210, 210, 210, 0.5), 0 2px 5px -1px rgba(171, 171, 171, 0.5);
    
    img {
        margin: 15px 0;
    }
    
    h5 {
        font-size: 16px;
        margin-bottom: 35px;
        font-weight: 500;
        line-height: 19px;
    }
    
    p {
        font-size: 14px;
        line-height: 19px;
        color: #919eab;
    }
}
