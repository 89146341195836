@import '../../../../../resources/sass/web/base/variables';

.zp-container {
    margin: 10px 0;
    position: relative;
    outline: 0;
    float: left;
    width: 100%;
    display: inline-block !important;
}

.zp-title {
    margin-bottom: 5px;

    > label {
        margin: 0;
    }
}

.zp-header {
    position: relative;
    float: left;
    width: 100%;

    > .input-control,
    > .dd-container {
        float: left;
        width: 50%;
    }

    > .input-control {
        padding-right: 5px;
        margin: 10px 0 0 0;
    }

    > .dd-container {
        padding-left: 5px;
    }

    &__icon {
        cursor: pointer;
        position: absolute;
        right: 15px;
        transform: translateY(-50%);
        top: 50%;
    }

    &__name {
        position: absolute;
        bottom: 0;
        left: 55px;
        height: 32px;
        pointer-events: none;

        > p {
            margin: 0;
        }
    }
}

.zp-list {
    top: 100%;
    background-color: #fff;
    border: 1px solid #cccccc;
    border-radius: 4px;
    max-height: 200px;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 1;

    &__options {
        list-style-type: none;
        padding: 0;
        margin: 0;

        // remove selected item styles while
        // going through option items
        &:hover &--item.selected {
            background-color: transparent;
            color: #000;
        }

        &--item {
            cursor: pointer;
            padding: 5px 10px;
            font-size: 16px;

            &:hover,
            &.selected {
                background-color: $btn-primary-hover;
                color: #fff;
            }

            &.highlighted {
                background-color: rgba(51, 164, 255, 0.5);
                color: #fff;
            }
        }
    }
}

.zp-error {
    float: left;
    width: 100%;

    > p {
        color: red;
        font-size: 12px;
        text-align: right;
        top: -10px;
        position: relative;
    }
}
