//
// Grid system
// --------------------------------------------------


.container {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: 1100px
  }
}


.container-fluid {
  @include container-fixed;
}


.row {
  @include make-row;
}


@include make-grid-columns;


@include make-grid(xs);


@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}


@media (min-width: $screen-md-min) {
  @include make-grid(md);
}


@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}

.mobile-padding {
    padding-left: 20px;
    padding-right: 20px;
}

.no-side-paddings {
    padding-left: 0;
    padding-right: 0;
}
