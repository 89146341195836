@import '../../../assets/styles/base/mixins';
@import '../../../assets/styles/base/variables';

.placeholder-garage-list-container {
    float: left;
    width: 100%;
    
    @media (min-width: $screen-md-min) {
        padding: 0;
    }

    @media (min-width: $screen-lg-min) {
        padding: 0;
    }

    .rect-shape {
        border-radius: 5px;
    }
}

.placeholder-garage-list-options {

    @media (min-width: $screen-lg-min) {
        margin: 15px 0 20px 0;
    }

    // option left
    .rect-shape:nth-child(1) {
        //margin-left: 10px;
        float: left;
    }

    // option right
    .rect-shape:nth-child(2) {
        margin-right: 0 !important;
        float: right;
    }
    
    .rect-shape:nth-child(3) {
        float: left !important;
        width: 100% !important;
        height: 50px !important;
        margin: 15px 0 0 0 !important;
    }

    .rect-shape:nth-child(1),
    .rect-shape:nth-child(2) {
        width: 40% !important;
        height: 30px !important;

        @media (min-width: $screen-lg-min) {
            width: 15% !important;
            margin: 0 !important;
        }
    }
}

.placeholder-garage-list-item-avatar {
    width: 100px;

    @media (min-width: $screen-lg-min) {
        margin-right: 30px;
    }

    // avatar img
    .rect-shape {
        width: 90px !important;
        height: 90px !important;

        @media (min-width: $screen-lg-min) {
            margin-left: 15px;
            margin-top: 5px;
            width: 85px !important;
            height: 85px !important;
        }
    }
}

.placeholder-garage-list-item-separator {
    @media (min-width: $screen-lg-min) {
        display: none;
    }
}

.placeholder-garage-list-item-detail {
    width: calc(100% - 100px);

    @media (min-width: $screen-lg-min) {
        margin-top: 5px;
        width: calc(65% - 100px);
    }
    
    &__title {
        height: 2em !important;
        margin-bottom: 10px;
        width: 80% !important;
        
        @media (min-width: $screen-sm-min) {
            width: 35% !important;
        }
    
        @media (min-width: $screen-md-min) {
            width: 80% !important;
        }
    }
    
    &__location,
    &__stars {
        margin: 5px 0;
        height: .8rem !important;
    }
    
    &__location {
        width: 60% !important;
    
        @media (min-width: $screen-sm-min) {
            width: 45% !important;
        }
    }
    
    &__stars {
        width: 30% !important;
    
        @media (min-width: $screen-sm-min) {
            width: 15% !important;
        }
    }
    
    // detail other
    .text-block {
        @media (min-width: $screen-lg-min) {
            width: 65% !important;
        }
    }
}

.placeholder-garage-list-item-ctas {
    @media (min-width: $screen-lg-min) {
        width: 20%;
        margin-right: 10px;
        float: right;
    }
    
    &__button {
        margin: 10px 0 0 0 !important;
        width: 120px !important;
        height: 40px !important;
        float: right;
    }

}

.placeholder-pos-relative-garage-item {
    position: relative;
    padding: 15px 20px;
    
    @media (min-width: $screen-lg-min) {
        min-height: 146px;
    }
}

.placeholder-garage-list-item-detail,
.placeholder-garage-list-item-avatar,
.placeholder-garage-list-item-ctas {
    @media (min-width: $screen-lg-min) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.placeholder-garage-list-item-detail {
    @media (min-width: $screen-lg-min) {
        left: 150px;
    
        &__title {
            width: 70% !important;
        }
    
        &__location {
            width: 40% !important;
        }
    
        &__stars {
            width: 25% !important;
        }
    }
}

.placeholder-garage-list-item-ctas {
    @media (min-width: $screen-md-min) {
        right: 15px;
    }
}
