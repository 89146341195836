@import '../../../../assets/styles/base/variables';
@import '../../../../assets/styles/base/mixins';

.official-service {
    margin: 30px 0 0 0;
    border-radius: 4px;
    user-select: none;
    background-color: #f1f1f1;
    
    @media (min-width: $screen-md-min) {
        margin: 30px 0 45px 0;
    }
    
    &--checked {
        background-color: #fff;
        box-shadow: 0 2px 24px -3px rgba(211, 211, 211, 0.5),
                    0 2px 5px -1px rgba(171, 171, 171, 0.5);
    }
    
    &__name {
        @include clearfix;
        position: relative;
        
        label {
            width: 100%;
        }
        
        .icon {
            padding: 22px;
            right: 0;
            position: absolute;
        }
    }
    
    .input-control {
        float: left;
        width: 100%;
        padding: 20px 0 20px 20px;
        
        label {
            color: #46515e;
            font-weight: 700;
        }
    }
    
    &__includes {
        list-style-type: none;
        padding: 0 20px 20px 20px;
        
        &:before {
            content: attr(data-label) ": ";
            color: $grayish;
            font-size: 12px;
        }
        
        > li {
            color: #46515e;
            font-size: 14px;
            font-weight: 700;
            line-height: 2.17;
        }
    }
}
