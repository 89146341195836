@import '../../../assets/styles/base/variables';

.step-indicator {
    text-align: center;
    height: 20px;
    margin: 15px 0;

    &__item {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: 0 5px;
        background-color: $step-bg-color;
        position: relative;
        transition: 0.2s ease-in;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            top: 2px;
            width: 12px;
            height: 12px;
            background-color: $step-bg-color-active;
        }
    }

}
