@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.item-extra-detail {
    width: 100%;
    float: left;
    margin: 0 5px 0 0;
    @include clearfix;

    @media (min-width: $screen-md-min) {
        margin: 0 10px 0 0;
    }

    &__square {
        float: left;
        border-radius: 4px;
        width: 13px;
        height: 26px;
        line-height: 28px;
        text-align: center;
        color: #fff;
        margin-right: 5px;
        font-size: 12px;
        position: relative;

        > span {
            font-weight: bold;
            font-size: 18px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 50%;
        }

        > span.yellow {
            color: #fed57d;
        }

        > span.blue {
            color: #3165ec;
        }

        > span.green {
            color: #19eabe;
        }
    }

    &__label {
        float: left;
        font-size: 12px;
        font-weight: 700;
        line-height: 28px;
        color: #919eab;
        width: calc(100% - 20px);
    }
}

.item-extra-detail-axa-partner{
    .item-extra-detail__label{
        border: 1px solid #dadada;
        display: inline-block;
        width: auto;
        padding: 4px 10px 2px 10px;
        line-height: normal;
        border-radius: 4px;
        color: #bcbcbc;
        font-family: $font-eina;
        font-weight: 700;
    }
}
