.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float:none !important;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.invisible {
    visibility: hidden;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.underline {
    text-decoration: underline;
}

.pointer {
    cursor: pointer;
}

.pull-l {
    float: left;
}

.pull-r {
    float: right;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-3 {
    margin-top: 3px !important;
}
.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mh-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mh-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.mh-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.p-0 {
    padding: 0 !important;
}

.pv-30 {
    padding: 0 30px !important;
}

.static {
    position: static !important;
}

.overflow-hidden {
    overflow: hidden;
}

.blurriness {
    cursor: pointer;
    color: transparent;
    text-shadow: 0 0 9px rgba(0, 138, 255, .5);

    &--visible {
        color: inherit;
        text-shadow: inherit;
    }
}

.hidden-mobile {
    @media (max-width: $screen-md-min) {
        display: none !important;
    }
}

.hidden-sm {
    @media (max-width: $screen-sm-min) {
        display: none !important;
    }
}

.hidden-desktop {
    @media (min-width: $screen-md-min) {
        display: none !important;
    }
}

.cut-text {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.move-right {
    float: right;
}

.text-capitalize {
    text-transform: capitalize !important;
}

@media (min-width: $screen-md-min) {
    .table {
        display: table;

        &--half {
            width: 50%;
        }

        &--full {
            width: 100%;
        }
    }

    .table > [class*="col-"],
    .table > [class*="container"],
    .table > [class*="container-fluid"] {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
    .table > [class*="center-middle"] {
        vertical-align: middle;
    }
}

.divider {
    text-align: center;

    &--vertical {
        float: left;
        background: #f8f8f8;
        height: 1px;
        width: 100%;
        margin: 30px 0;
    }

    > span {
        display: inline-block;
        padding: 5px 20px;
        position: relative;
        top: -15px;
        font-size: 15px;

        &.white {
            background: #fff;
        }
    }
}

.notify-number {
    position: absolute;
    right: 5px;
    top: 5px;
    background: $alert-danger-primary;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 1.9;
    text-align: center;
}

.js-garage-item-acc-header .accordion-header__icon {
    color: $brand-primary;
}

.visible-md {
    display: none !important;

    @media (min-width: $screen-md-min) {
        display: block !important;
    }
}

.hidden-md-plus {
    display: block !important;

    @media (min-width: $screen-md-min) {
        display: none !important;
    }
}

.font-atto{
    font-family: $font-atto;
}
