@font-face {
    font-family: 'carhelper';
    src: url($font-path + 'carhelper/carhelper.eot?ojqcba');
    src: url($font-path + 'carhelper/carhelper.eot?ojqcba#iefix') format('embedded-opentype'),
    url($font-path + 'carhelper/carhelper.ttf?ojqcba') format('truetype'),
    url($font-path + 'carhelper/carhelper.woff?ojqcba') format('woff'),
    url($font-path + 'carhelper/carhelper.svg?ojqcba#carhelper') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'carhelper' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
    content: "\e93e";
}

.icon-play:before {
    content: "\e93d";
}

.icon-search:before {
    content: "\e93c";
}

.icon-correct:before {
    content: "\e93b";
}

.icon-filter:before {
    content: "\e942";
}

.icon-sort:before {
    content: "\e941";
}

.icon-check-circle-full:before {
    content: "\e93f";
}

.icon-chevron-left-bold:before {
    content: "\e940";
}

.icon-time:before {
    content: "\e936";
}

.icon-coffee:before {
    content: "\e937";
}

.icon-publictransport:before {
    content: "\e938";
}

.icon-support:before {
    content: "\e939";
}

.icon-full-star:before {
    content: "\e935";
}

.icon-check-circle-thicker:before {
    content: "\e930";
}

.icon-star-thicker:before {
    content: "\e931";
}

.icon-rise-thicker:before {
    content: "\e932";
}

.icon-percentage-thicker:before {
    content: "\e933";
}

.icon-map-marker-thicker:before {
    content: "\e934";
}

.icon-date-picker:before {
    content: "\e93a";
}

.icon-question-circle:before {
    content: "\e91c";
}

.icon-map-marker:before {
    content: "\e91a";
}

.icon-add:before {
    content: "\e92e";
}

.icon-rise:before {
    content: "\e911";
}

.icon-star:before {
    content: "\e925";
}

.icon-trophy:before {
    content: "\e926";
}

.icon-percentage:before {
    content: "\e928";
}

.icon-placeholder1:before {
    content: "\e929";
}

.icon-placeholder2:before {
    content: "\e92b";
}

.icon-placeholder3:before {
    content: "\e92c";
}

.icon-placeholder4:before {
    content: "\e92d";
}

.icon-reply:before {
    content: "\e923";
}

.icon-download:before {
    content: "\e924";
}

.icon-license:before {
    content: "\e91f";
}

.icon-list:before {
    content: "\e920";
}

.icon-select:before {
    content: "\e921";
}

.icon-thumbs-up:before {
    content: "\e922";
}

.icon-attach:before {
    content: "\e91e";
}

.icon-arrow-left:before {
    content: "\e91d";
}

.icon-check-circle:before {
    content: "\e90f";
}

.icon-check1:before {
    content: "\e910";
}

.icon-drop-down:before {
    content: "\e92f";
}

.icon-arrow-right:before {
    content: "\e912";
}

.icon-chevron-down:before {
    content: "\e913";
}

.icon-chevron-left:before {
    content: "\e914";
}

.icon-chevron-right:before {
    content: "\e915";
}

.icon-chevron-up:before {
    content: "\e916";
}

.icon-cross-circle:before {
    content: "\e917";
}

.icon-cross:before {
    content: "\e918";
}

.icon-magnifier:before {
    content: "\e919";
}

.icon-menu:before {
    content: "\e91b";
}

.icon-car-fluids:before {
    content: "\e927";
}

.icon-car-hourlyprice:before {
    content: "\e92a";
}

.icon-car-paint:before {
    content: "\e900";
}

.icon-customisation:before {
    content: "\e901";
}

.icon-wheel-axes:before {
    content: "\e902";
}

.icon-car-repair:before {
    content: "\e903";
}

.icon-car-wash:before {
    content: "\e904";
}

.icon-AC:before {
    content: "\e905";
}

.icon-accumulator:before {
    content: "\e906";
}

.icon-engine:before {
    content: "\e907";
}

.icon-oil:before {
    content: "\e908";
}

.icon-parts:before {
    content: "\e909";
}

.icon-tire:before {
    content: "\e90a";
}

.icon-convertible:before {
    content: "\e90b";
}

.icon-gear:before {
    content: "\e90c";
}

.icon-utilisation:before {
    content: "\e90d";
}

.icon-car-check:before {
    content: "\e90e";
}

.icon-kg:before {
    font-weight: 300;
    content: "kg";
}


