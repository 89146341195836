// put in input-range.scss
div.input-range {
    margin-bottom: 30px;
    float: left;
    width: 100%;
}

div.input-range__slider {
    box-shadow: 2px 2px 7px 0 rgba(219, 219, 219, 0.5);
    border: solid 5px #f7f7f7;
    background-color: $brand-primary;
    width: 24px;
    height: 24px;
}

div.input-range__track {
    height: 2px;
}

div.input-range__track--active {
    background-color: $brand-primary;
}

span.input-range__label {
    display: none;
}

span.input-range__label--min,
span.input-range__label--max {
    bottom: 30px;
}

span.input-range__label--min {
    left: 0;
    
    .input-range__label-container {
        left: 0;
    }
}

span.input-range__label--max {
    right: 0;
    
    .input-range__label-container {
        left: 0;
    }
}

.input-range__container {
    float: left;
    width: 100%;
    
    &--disabled {
        opacity: .5;
        pointer-events: none;
    }
}
.input-range__header{
    > p.title {
        color: #919eab;
        font-weight: 500;
        margin-bottom: 5px;
        font-size: 14px;
        margin-top: 15px;
    }
    
    > p.value {
        font-weight: 500;
        font-size: 12px;
        color: #000;
    }
}
