@import '../../assets/styles/base/variables';
@import '../../assets/styles/base/mixins';

.recommended-from {
    &__logo {
        max-height: 25px;
        margin-left: 10px;
    }
}

.save-up-to {
    padding: 15px 15px;
    margin-bottom: 30px;

    &--low-percentage {
        text-align: center;
        background-color: $save-up-to-bg-low;
    }

    &--high-percentage {
        background-color: $save-up-to-bg-high;
    }

    > p {
        margin: 0;
        font-weight: 500;
    }
}

.aside-cta {
    &--fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10;
    }
}

#btn-garage-list {
    @media (min-width: $screen-md-min) {
        display: none;
    }
}

.ior-token{
    display: none !important;
}

.service-page-container {
    width: 100%;
    
    @media (min-width: $screen-md-min) {
        width: 960px;
        padding: 70px 0 0;
    }
    
    @media (min-width: $screen-lg-min) {
        width: 1100px;
        padding-top: 70px;
    }
}

.service-page-container,
.service-page-sidebar {
    @media (max-width: $screen-md-min) {
        padding: 0 !important;
    }
}

.title-service {
    color: $grayish;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
    
    @media (min-width: $screen-md-min) {
        font-size: 14px;
    }
}

.text-service {
    font-weight: 700;
    color: #606060;
    font-size: 12px;
    line-height: 1.58;
    letter-spacing: -0.16px;
}

.service-page-sidebar .accordion__body {
    padding: 0 25px 0 25px;
    
    @media (min-width: $screen-md-min) {
        padding: 0 0 25px 0;
    }
}
