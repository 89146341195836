@import '../../../../../resources/sass/web/base/variables';

.gsb {
    &__container {
        border-radius: 4px;
    }

    &__title {
        width: 35%;
    }

    &__cta {
        width: 65%;
    }

    &__title,
    &__cta {
        float: left;

        @media (min-width: $screen-md-min) {
            width: 50%;
        }

        > p {
            color: #637381;
            font-size: 12px;
            cursor: pointer;
            font-weight: 700;
            display: inline;
            margin: 0;

            @media (min-width: $screen-md-min) {
                font-size: 14px;
            }
        }
    }
    
    &__title {
        position: relative;
        
        > p {
            display: inline-block;
            padding: 15px 15px 15px 20px;
        }
    }
    
    &__title-icon {
        position: absolute;
        top: 18px;
    }
    
    &__cta {
        position: relative;
        font-family: $font-atto;
        font-weight: 700;
        color: #bac7d5;
        text-align: right;
        padding: 15px 0;
    }
    
    &__cta-text {
        position: relative;
        color: #637381;
        font-size: 14px;
        font-weight: 700;
        display: inline;
        margin: 0;
        cursor: pointer;
        padding: 15px 0 15px 20px;
    }
    
    &__cta-icon {
        position: absolute;
        left: 0;
        top: 17px;
        pointer-events: none;
    }
}

.gsb-dropdown {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: #fff;
    padding: 15px 30px 15px 45px;
    text-align: left;
    right: -15px;
    border-radius: 4px;
    box-shadow: 0 2px 37px -11px rgba(188, 188, 188, 0.5), 0 2px 5px -1px rgba(72, 72, 72, 0.5);
    
    @media (min-width: $screen-md-min) {
        top: 65px;
    }
    
    &__title {
        position: relative;
        
        > p {
            font-size: 12px;
            font-weight: 700;
        }
    }
    
    &__title-icon {
        left: -20px;
        position: absolute;
        top: 4px;
    }
    
    &__list-item {
        color: #919eab;
        cursor: pointer;
        
        > p {
            font-size: 14px;
            font-weight: 700;
            margin: 0 0 10px 0;
        }
        
        &--active {
            color: #000;
        }
        
        &:last-child p {
            margin: 0;
        }
    }
}
