@media (min-width: $screen-md-min) {
    .ctg-filter-dropdown-enter {
        //opacity: .8;
        height: 150px !important;
        transform: translate(20px, 20px);
        width: calc(100% - 40px);
    }
    
    .ctg-filter-dropdown-enter-active {
        transition: 450ms ease-in;
    }
    
    .ctg-filter-dropdown-enter.ctg-filter-dropdown-enter-active {
        //opacity: 1;
        height: 300px !important;
        transform: translate(-15px, -15px);
        width: calc(100% + 30px);
    }
    
    .ctg-filter-dropdown-leave-active {
        transition: 300ms ease-in;
    }
    
    .ctg-filter-dropdown-leave {
        overflow: scroll;
        //opacity: 1;
        height: 300px !important;
        transform: translate(-15px, -15px);
        width: 100%;
    }
    
    .ctg-filter-dropdown-leave.ctg-filter-dropdown-leave-active {
        //opacity: .8;
        height: 250px !important;
        transform: translate(-5px, -5px);
        width: calc(100% + 10px);
    }
}
