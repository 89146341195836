@import '../../../assets/styles/base/mixins';
@import '../../../assets/styles/base/variables';

.ph-preselected-service-title {
    height: 2em !important;
    width: 50% !important;
    margin-bottom: 10px !important;
}

.ph-preselected-service-body {
    height: 5em !important;
}
