@import '../base/mixins';
@import '../base/variables';

.rect-shape,
.text-row,
.round-shape {
    background-color: $placeholder-primary;
}

.placeholder-container {
    margin: 15px 0;
    @include clearfix;

    &--white-bg {
        background-color: #fff;
    }
}

.placeholder-row {
    float: left;
    width: 100%;
    @include clearfix;

    &--paddings {
        padding: 15px 15px;
    }

}

.placeholder-separator {
    position: relative;
    height: 30px;

    &:after {
        background: $placeholder-primary;
        content: " ";
        position: absolute;
        top: 0;
        width: 100%;
        height: 1px;
        bottom: 0;
        margin: auto;
    }
}
