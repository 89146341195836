@import '../main/base/variables';

$text-light: #9b9b9b;
$step-bg-color: #cccccc;
$step-bg-color-active: #9b9b9b;
$text-dark: #46515e;
$text-light-dark: #919eab;
$line-word-separator: #f5f5f5;

$font-path: '../fonts/';

// save up to
$save-up-to-bg-low: #effaff;
$save-up-to-bg-high: $alert-success-secondary;

// labels
$label-success-primary: #76966a;
$label-success-secondary: #f3fbf0;
$label-success-lighten: #a1d881;
$label-warning-primary: #c19c49;
$label-warning-secondary: #fbf7f0;
$label-warning-lighten: #ffd440;
$label-info-primary: #0f6b9f;
$label-info-secondary: #f0f6fb;
$label-info-lighten: #1cadff;
$label-dark-primary: #FFFFFF;

// Buttons
$button-ad-garage-primary: #013299;
$button-ad-garage-secondary: #dae5fa;
$button-ad-garage-hover: lighten($button-ad-garage-secondary, 2%);
$button-garage-load-more-primary: #fff;
$button-garage-load-more-secondary: #c4cdd5;
$button-garage-load-more-hover: lighten($button-garage-load-more-secondary, 2%);

$btn-terciary-primary: #778699;
$btn-terciary-secondary: #dce1e7;

// Placeholder:
$placeholder-primary: #e8e8e8;
