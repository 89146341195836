@font-face {
  font-family: 'Eina';
  font-weight: 400;
  src: url("../fonts/Eina01/Eina01-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Eina';
  font-weight: 500;
  src: url("../fonts/Eina01/Eina01-SemiBold.ttf") format("truetype"); }

@font-face {
  font-family: 'Eina';
  font-weight: 700;
  src: url("../fonts/Eina01/Eina01-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Atto';
  font-weight: 300;
  src: url("../fonts/Atto/atto_light-webfont.ttf") format("truetype"); }

@font-face {
  font-family: 'Atto';
  font-weight: 400;
  src: url("../fonts/Atto/atto-webfont.ttf") format("truetype"); }

@font-face {
  font-family: 'Atto';
  font-weight: 500;
  src: url("../fonts/Atto/atto_medium-webfont.ttf") format("truetype"); }

@font-face {
  font-family: 'Atto';
  font-weight: 700;
  src: url("../fonts/Atto/atto_bold-webfont.ttf") format("truetype"); }

@font-face {
  font-family: 'carhelper';
  src: url("../fonts/carhelper/carhelper.eot?ojqcba");
  src: url("../fonts/carhelper/carhelper.eot?ojqcba#iefix") format("embedded-opentype"), url("../fonts/carhelper/carhelper.ttf?ojqcba") format("truetype"), url("../fonts/carhelper/carhelper.woff?ojqcba") format("woff"), url("../fonts/carhelper/carhelper.svg?ojqcba#carhelper") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'carhelper' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-close:before {
  content: "\e93e"; }

.icon-play:before {
  content: "\e93d"; }

.icon-search:before {
  content: "\e93c"; }

.icon-correct:before {
  content: "\e93b"; }

.icon-filter:before {
  content: "\e942"; }

.icon-sort:before {
  content: "\e941"; }

.icon-check-circle-full:before {
  content: "\e93f"; }

.icon-chevron-left-bold:before {
  content: "\e940"; }

.icon-time:before {
  content: "\e936"; }

.icon-coffee:before {
  content: "\e937"; }

.icon-publictransport:before {
  content: "\e938"; }

.icon-support:before {
  content: "\e939"; }

.icon-full-star:before {
  content: "\e935"; }

.icon-check-circle-thicker:before {
  content: "\e930"; }

.icon-star-thicker:before {
  content: "\e931"; }

.icon-rise-thicker:before {
  content: "\e932"; }

.icon-percentage-thicker:before {
  content: "\e933"; }

.icon-map-marker-thicker:before {
  content: "\e934"; }

.icon-date-picker:before {
  content: "\e93a"; }

.icon-question-circle:before {
  content: "\e91c"; }

.icon-map-marker:before {
  content: "\e91a"; }

.icon-add:before {
  content: "\e92e"; }

.icon-rise:before {
  content: "\e911"; }

.icon-star:before {
  content: "\e925"; }

.icon-trophy:before {
  content: "\e926"; }

.icon-percentage:before {
  content: "\e928"; }

.icon-placeholder1:before {
  content: "\e929"; }

.icon-placeholder2:before {
  content: "\e92b"; }

.icon-placeholder3:before {
  content: "\e92c"; }

.icon-placeholder4:before {
  content: "\e92d"; }

.icon-reply:before {
  content: "\e923"; }

.icon-download:before {
  content: "\e924"; }

.icon-license:before {
  content: "\e91f"; }

.icon-list:before {
  content: "\e920"; }

.icon-select:before {
  content: "\e921"; }

.icon-thumbs-up:before {
  content: "\e922"; }

.icon-attach:before {
  content: "\e91e"; }

.icon-arrow-left:before {
  content: "\e91d"; }

.icon-check-circle:before {
  content: "\e90f"; }

.icon-check1:before {
  content: "\e910"; }

.icon-drop-down:before {
  content: "\e92f"; }

.icon-arrow-right:before {
  content: "\e912"; }

.icon-chevron-down:before {
  content: "\e913"; }

.icon-chevron-left:before {
  content: "\e914"; }

.icon-chevron-right:before {
  content: "\e915"; }

.icon-chevron-up:before {
  content: "\e916"; }

.icon-cross-circle:before {
  content: "\e917"; }

.icon-cross:before {
  content: "\e918"; }

.icon-magnifier:before {
  content: "\e919"; }

.icon-menu:before {
  content: "\e91b"; }

.icon-car-fluids:before {
  content: "\e927"; }

.icon-car-hourlyprice:before {
  content: "\e92a"; }

.icon-car-paint:before {
  content: "\e900"; }

.icon-customisation:before {
  content: "\e901"; }

.icon-wheel-axes:before {
  content: "\e902"; }

.icon-car-repair:before {
  content: "\e903"; }

.icon-car-wash:before {
  content: "\e904"; }

.icon-AC:before {
  content: "\e905"; }

.icon-accumulator:before {
  content: "\e906"; }

.icon-engine:before {
  content: "\e907"; }

.icon-oil:before {
  content: "\e908"; }

.icon-parts:before {
  content: "\e909"; }

.icon-tire:before {
  content: "\e90a"; }

.icon-convertible:before {
  content: "\e90b"; }

.icon-gear:before {
  content: "\e90c"; }

.icon-utilisation:before {
  content: "\e90d"; }

.icon-car-check:before {
  content: "\e90e"; }

.icon-kg:before {
  font-weight: 300;
  content: "kg"; }

label {
  font-weight: normal;
  margin-bottom: 14px;
  display: inline-block;
  color: #4a4a4a; }
  label a {
    font-size: 14px; }

.form-group label {
  width: 85%;
  word-wrap: break-word; }

.form-response {
  text-align: center;
  display: none;
  position: relative; }
  .form-response > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .form-response__animation {
    width: 70px !important;
    height: 70px !important; }
  .form-response__message {
    color: #919eab; }

form:after {
  content: '';
  clear: both;
  display: block; }

select,
input[type="text"],
input[type="email"],
input[type="number"],
input[readonly],
input[type="password"],
input[type="tel"],
textarea,
select::placeholder,
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[readonly]::placeholder,
input[type="tel"]::placeholder,
textarea::placeholder {
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal; }

select,
input[type="text"],
input[type="email"],
input[type="number"],
input[readonly],
input[type="password"],
input[type="tel"],
textarea {
  display: block;
  width: 100%;
  margin: 10px 0;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  min-height: 36px;
  height: 44px;
  padding: 11px 10px 10px 10px;
  outline: 0; }
  select[disabled],
  input[type="text"][disabled],
  input[type="email"][disabled],
  input[type="number"][disabled],
  input[readonly][disabled],
  input[type="password"][disabled],
  input[type="tel"][disabled],
  textarea[disabled] {
    background-color: #dfe3e8;
    opacity: 0.5;
    cursor: not-allowed; }
  select[readonly],
  input[type="text"][readonly],
  input[type="email"][readonly],
  input[type="number"][readonly],
  input[readonly][readonly],
  input[type="password"][readonly],
  input[type="tel"][readonly],
  textarea[readonly] {
    cursor: default; }
  select:not(.input-error):focus,
  input[type="text"]:not(.input-error):focus,
  input[type="email"]:not(.input-error):focus,
  input[type="number"]:not(.input-error):focus,
  input[readonly]:not(.input-error):focus,
  input[type="password"]:not(.input-error):focus,
  input[type="tel"]:not(.input-error):focus,
  textarea:not(.input-error):focus {
    border: 1px solid #008dff; }
  select::placeholder,
  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="number"]::placeholder,
  input[readonly]::placeholder,
  input[type="password"]::placeholder,
  input[type="tel"]::placeholder,
  textarea::placeholder {
    color: #9b9b9b; }
  select:-ms-input-placeholder,
  input[type="text"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  input[readonly]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #9b9b9b; }

textarea {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0 !important;
  color: #000 !important;
  font-weight: bold !important;
  font-size: 15px !important;
  border: 1px solid #cccccc;
  font-family: "Atto", Helvetica, Arial, sans-serif;
  border-radius: 4px; }

input.input-error,
textarea.input-error {
  border: 1px solid #e74c3c;
  background-color: #fbeae5; }

input.input-suffix {
  padding-right: 50px; }

.input-prefix,
.input-suffix {
  position: relative;
  font-family: "FontAwesome", sans-serif;
  font-size: 21px;
  float: left;
  width: 100%; }
  .input-prefix::before, .input-prefix::after,
  .input-suffix::before,
  .input-suffix::after {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    text-align: center;
    opacity: 0.5;
    padding-top: 8px; }

.input-prefix input:first-child {
  padding-left: 40px; }

.input-prefix::before {
  left: 0; }

.input-prefix--dollar::before {
  content: "\f155"; }

.input-prefix--search::before {
  content: "\f002"; }

.input-prefix--envelope::before {
  content: "\f0e0"; }

.input-suffix input:first-child {
  padding-right: 40px; }

.input-suffix::after {
  right: 0; }

.input-suffix--kg::after {
  content: "kg"; }

.input-suffix--close::after {
  content: "\f057"; }

.form-inline {
  position: relative;
  float: left;
  width: 100%;
  margin: 10px 0; }
  .form-inline input {
    margin: 0; }

.icpr {
  padding-right: 15px; }

.icpl {
  padding-left: 15px; }

.input-control {
  position: relative;
  float: left;
  width: 100%;
  margin: 10px 0; }
  .input-control--social {
    text-align: center; }
  .input-control--half {
    width: 50%;
    float: left; }
  .input-control input,
  .input-control textarea {
    margin: 5px 0 0 0; }
  .input-control label {
    font-weight: 500;
    font-size: 14px; }

.input-control-icons {
  float: left;
  width: 100%;
  margin: 10px 0;
  position: relative; }
  .input-control-icons.left-padding > input {
    padding: 10px 10px 10px 40px; }
  .input-control-icons.right-padding > input {
    padding: 10px 40px 10px 10px; }
  .input-control-icons.both-padding > input {
    padding: 10px 40px 10px 40px; }
  .input-control-icons > input {
    margin: 0; }

.input-prefix {
  width: 42px;
  height: 42px;
  position: absolute;
  text-align: center;
  top: 0;
  opacity: 0.5;
  pointer-events: none;
  cursor: default; }
  .input-prefix.clickable {
    pointer-events: initial;
    cursor: pointer; }
  .input-prefix:hover {
    opacity: 1; }
  .input-prefix--right {
    right: 0; }
  .input-prefix--left {
    left: 0; }
  .input-prefix::before {
    top: 50%;
    transform: translateY(-50%);
    margin: 2px auto 0 auto;
    text-align: center;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0; }

.customCheckbox {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 5px;
  overflow: hidden;
  border: 2px solid #c4cdd5;
  top: 2px; }
  .customCheckbox.customCheckboxChecked {
    border: 2px solid #008dff;
    border-radius: 4px; }
    .customCheckbox.customCheckboxChecked > span {
      display: block; }
  .customCheckbox input {
    opacity: 0;
    cursor: pointer;
    z-index: 5;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0; }
  .customCheckbox span {
    color: #008dff;
    display: none;
    text-align: center;
    line-height: 17px;
    font-size: 12px; }

input[readonly] {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  user-select: none; }
  input[readonly]:focus {
    border: 0 !important; }

input[type=file] {
  display: none; }

.garage-phone-input {
  margin-top: 15px; }

.has-error {
  font-size: 12px;
  color: #dd122c;
  margin-top: 0;
  display: inline-block;
  position: absolute;
  text-align: right;
  width: 100%; }

.has-success {
  font-size: 12px;
  color: #1c9249;
  margin-top: 0;
  display: inline-block;
  position: absolute;
  text-align: right;
  width: 100%; }

.success-text {
  color: #1c9249; }

.is-invalid > input {
  border: 1px solid #e74c3c;
  background-color: #fbeae5; }
  .is-invalid > input:focus {
    border: 1px solid #e74c3c !important;
    background-color: #fbeae5; }

.is-invalid > small {
  display: inline-block;
  width: 100%;
  text-align: right; }

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

html {
  height: 100%; }

body {
  margin: 0;
  font-family: "Atto", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: #292929;
  font-weight: 400;
  background: #F8F9FA;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-y: scroll; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

[hidden],
template, .hide {
  display: none; }

b,
strong {
  font-weight: bold; }

small,
.small {
  font-size: 85%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0;
  border-top: 1px solid white; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  line-height: inherit;
  margin: 0; }

select {
  background-color: #FFFFFF; }

button {
  overflow: visible;
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #cccccc;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

a {
  background-color: transparent;
  color: #008dff;
  text-decoration: none; }

a:hover,
a:focus {
  outline: 0;
  color: #0063b3;
  text-decoration: none; }

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

[role="button"] {
  cursor: pointer; }

figure {
  margin: 0; }

img {
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

[role="button"] {
  cursor: pointer; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
  line-height: 1.1;
  color: inherit; }

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777777; }

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px; }

h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%; }

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px; }

h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%; }

h1,
.h1 {
  font-size: 36px; }

h2,
.h2 {
  font-size: 30px; }

h3,
.h3 {
  font-size: 24px; }

h4,
.h4 {
  font-size: 18px; }

h5,
.h5 {
  font-size: 14px; }

h6,
.h6 {
  font-size: 12px; }

p {
  margin: 0 0 10px; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px; }

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0; }

ul.unstyled {
  list-style-type: none;
  padding: 0;
  margin: 0; }

a {
  font-size: 16px;
  line-height: 22px;
  transition: .2s ease-in; }
  a.dark {
    color: #000; }
  a.red {
    color: red; }

a:hover {
  color: #E0D9E6; }

a.active {
  color: #008dff; }

a,
a:focus,
a:active {
  color: inherit; }

p.text-light {
  margin: 0;
  font-size: 16px;
  font-family: "Atto-Regular", sans-serif;
  color: #9b9b9b; }

.breadcrumbs {
  list-style-type: none;
  padding: 0; }
  .breadcrumbs > li {
    display: inline;
    color: #ccc;
    margin: 0;
    font-size: "Atto", sans-serif; }
    .breadcrumbs > li:not(:first-child) {
      padding-left: 15px;
      position: relative; }
      .breadcrumbs > li:not(:first-child):before {
        font-family: carhelper;
        content: "\E915";
        position: absolute;
        left: 0;
        width: 13px;
        height: 19px;
        font-size: 12px;
        top: 1px; }

h1.dark, h2.dark, h3.dark, h4.dark, h5.dark, h6.dark {
  color: #292929; }

h1.light, h2.light, h3.light, h4.light, h5.light, h6.light {
  color: #808080; }

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  color: inherit;
  font-size: inherit; }

h1 {
  margin: 0 0 15px 0;
  font-family: "Eina", sans-serif;
  font-weight: 700;
  font-size: 36px; }
  @media (min-width: 992px) {
    h1 {
      font-size: 54px; } }

h2 {
  font-family: "Eina", sans-serif;
  font-weight: 700;
  font-size: 40px; }
  h2.wilkommen {
    font-size: 36px; }
  h2.key-numbers {
    font-size: 40px;
    line-height: 1.1; }

h3 {
  font-family: "Eina", sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px; }

h4 {
  font-size: 24px;
  line-height: 34px; }
  h4.black-ber {
    margin: 15px 0 30px 0;
    font-family: "Eina", sans-serif;
    font-weight: 700;
    font-size: 22px;
    line-height: 1.09; }

h5 {
  font-family: "Eina", sans-serif;
  font-weight: 700;
  line-height: 22px;
  font-size: 22px; }

h6 {
  font-weight: 300;
  font-size: 22px;
  line-height: 29px; }

p {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px; }
  p.light {
    color: #9b9b9b; }

.menu-element {
  font-family: "Eina", sans-serif;
  font-size: 22px;
  line-height: 1;
  color: #292929; }

.text, .text-strong {
  font-size: 16px;
  line-height: 22px;
  color: #9b9b9b; }
  .text--dark {
    color: #000; }

.text-strong {
  color: #000;
  font-weight: 700; }

.text-regular-15 {
  font-size: 15px; }

.text-regular-15 > .text-strong {
  margin-bottom: 5px;
  display: inline-block; }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
  float: none !important; }

.show {
  display: block; }

.hide {
  display: none; }

.invisible {
  visibility: hidden; }

.text-right {
  text-align: right !important; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-uppercase {
  text-transform: uppercase; }

.text-lowercase {
  text-transform: lowercase; }

.underline {
  text-decoration: underline; }

.pointer {
  cursor: pointer; }

.pull-l {
  float: left; }

.pull-r {
  float: right; }

.mt-0 {
  margin-top: 0 !important; }

.mt-3 {
  margin-top: 3px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mh-10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.mh-20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.mh-30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.p-0 {
  padding: 0 !important; }

.pv-30 {
  padding: 0 30px !important; }

.static {
  position: static !important; }

.overflow-hidden {
  overflow: hidden; }

.blurriness {
  cursor: pointer;
  color: transparent;
  text-shadow: 0 0 9px rgba(0, 138, 255, 0.5); }
  .blurriness--visible {
    color: inherit;
    text-shadow: inherit; }

@media (max-width: 992px) {
  .hidden-mobile {
    display: none !important; } }

@media (max-width: 768px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) {
  .hidden-desktop {
    display: none !important; } }

.cut-text {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap; }

.move-right {
  float: right; }

.text-capitalize {
  text-transform: capitalize !important; }

@media (min-width: 992px) {
  .table {
    display: table; }
    .table--half {
      width: 50%; }
    .table--full {
      width: 100%; }
  .table > [class*="col-"],
  .table > [class*="container"],
  .table > [class*="container-fluid"] {
    float: none;
    display: table-cell;
    vertical-align: top; }
  .table > [class*="center-middle"] {
    vertical-align: middle; } }

.divider {
  text-align: center; }
  .divider--vertical {
    float: left;
    background: #f8f8f8;
    height: 1px;
    width: 100%;
    margin: 30px 0; }
  .divider > span {
    display: inline-block;
    padding: 5px 20px;
    position: relative;
    top: -15px;
    font-size: 15px; }
    .divider > span.white {
      background: #fff; }

.notify-number {
  position: absolute;
  right: 5px;
  top: 5px;
  background: #e74c3c;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 1.9;
  text-align: center; }

.js-garage-item-acc-header .accordion-header__icon {
  color: #008dff; }

.visible-md {
  display: none !important; }
  @media (min-width: 992px) {
    .visible-md {
      display: block !important; } }

.hidden-md-plus {
  display: block !important; }
  @media (min-width: 992px) {
    .hidden-md-plus {
      display: none !important; } }

.font-atto {
  font-family: "Atto", sans-serif; }

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important; }
  .label {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

.btn {
  border: 0;
  margin: 15px 0;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  outline: 0 !important;
  box-sizing: border-box;
  font-weight: 500;
  transition: 0.2s ease-in; }
  .btn--rounded {
    border-radius: 50px; }
  .btn[disabled] {
    cursor: not-allowed; }
    .btn[disabled]:hover, .btn[disabled]:active, .btn[disabled]:focus {
      outline: 0 !important;
      background: rgba(0, 0, 0, 0.3); }

.btn-primary {
  background-color: #008dff;
  color: #fff; }
  .btn-primary:active, .btn-primary:focus {
    color: #fff; }
  .btn-primary[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important; }
  .btn-primary:not([disabled]):hover {
    background-color: #33a4ff;
    color: #fff; }

.btn-secondary {
  background-color: #fff;
  color: #008dff;
  box-shadow: inset 0px 0px 0px 2px #008dff; }
  .btn-secondary:active, .btn-secondary:focus {
    color: #008dff; }
  .btn-secondary[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important;
    box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.3); }
  .btn-secondary:not([disabled]):hover {
    background-color: white;
    color: #008dff;
    box-shadow: inset 0px 0px 0px 2px #008dff;
    color: #fff;
    background-color: #008dff; }

.btn-black {
  background-color: #000;
  color: #fff; }
  .btn-black:active, .btn-black:focus {
    color: #fff; }
  .btn-black[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important; }
  .btn-black:not([disabled]):hover {
    background-color: #1a1a1a;
    color: #fff; }

.btn-success {
  background-color: #2ecc71;
  color: #fff; }
  .btn-success:active, .btn-success:focus {
    color: #fff; }
  .btn-success[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important; }
  .btn-success:not([disabled]):hover {
    background-color: #54d98c;
    color: #fff; }

.btn-grayed-out {
  background-color: #e6e6e6;
  color: #46515e; }
  .btn-grayed-out:active, .btn-grayed-out:focus {
    color: #46515e; }
  .btn-grayed-out[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important; }
  .btn-grayed-out:not([disabled]):hover {
    background-color: white;
    color: #46515e; }

.btn-bluish {
  background-color: #e8edf1;
  color: #46515e;
  padding: 15px 40px !important;
  font-size: 16px; }
  .btn-bluish:active, .btn-bluish:focus {
    color: #46515e; }
  .btn-bluish[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important; }
  .btn-bluish:not([disabled]):hover {
    background-color: white;
    color: #46515e; }
  .btn-bluish:hover {
    background-color: #e8edf1 !important; }

.btn-transparent {
  background-color: transparent;
  color: #000; }
  .btn-transparent:active, .btn-transparent:focus {
    color: #000; }
  .btn-transparent[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important; }
  .btn-transparent:not([disabled]):hover {
    background-color: rgba(26, 26, 26, 0);
    color: #000; }

.btn-transparent-info {
  background-color: transparent;
  color: #008dff; }
  .btn-transparent-info:active, .btn-transparent-info:focus {
    color: #008dff; }
  .btn-transparent-info[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important; }
  .btn-transparent-info:not([disabled]):hover {
    background-color: rgba(26, 26, 26, 0);
    color: #008dff; }

.btn-transparent-danger {
  background-color: transparent;
  color: #dd122c; }
  .btn-transparent-danger:active, .btn-transparent-danger:focus {
    color: #dd122c; }
  .btn-transparent-danger[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important; }
  .btn-transparent-danger:not([disabled]):hover {
    background-color: rgba(26, 26, 26, 0);
    color: #dd122c; }

.btn-gray {
  background-color: #c3c3c3;
  color: #fff; }
  .btn-gray:active, .btn-gray:focus {
    color: #fff; }
  .btn-gray[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important; }
  .btn-gray:not([disabled]):hover {
    background-color: #dddddd;
    color: #fff; }

.btn-transparent--outline-white {
  background-color: transparent;
  color: #fff;
  box-shadow: inset 0px 0px 0px 2px #fff; }
  .btn-transparent--outline-white:active, .btn-transparent--outline-white:focus {
    color: #fff; }
  .btn-transparent--outline-white[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important;
    box-shadow: inset 0px 0px 0px 2px rgba(0, 0, 0, 0.3); }
  .btn-transparent--outline-white:not([disabled]):hover {
    background-color: rgba(26, 26, 26, 0);
    color: #fff;
    box-shadow: inset 0px 0px 0px 2px #fff;
    color: transparent;
    background-color: #fff; }
  .btn-transparent--outline-white:not([disabled]):hover {
    color: #000 !important; }

a.btn-google,
a.btn-facebook {
  font-size: 14px;
  color: gray;
  padding: 10px 10px !important;
  width: 90%;
  border: 1px solid #cecece; }

a.btn-google > img {
  margin-right: 5px; }

.btn-primary-light {
  background-color: #e6f4ff;
  color: #000; }
  .btn-primary-light:active, .btn-primary-light:focus {
    color: #000; }
  .btn-primary-light[disabled] {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.3);
    color: #000 !important; }
  .btn-primary-light:not([disabled]):hover {
    background-color: white;
    color: #000; }
  .btn-primary-light:not([disabled]):hover {
    background-color: #33a4ff;
    color: #fff; }

.btn-sm {
  font-size: 12px;
  padding: 10px 17px !important; }

.btn-lg {
  font-size: 17px;
  padding: 15px 28px !important; }

.btn-md {
  font-size: 17px;
  padding: 15px 20px !important; }

.btn-lg {
  width: 100%; }
  @media (min-width: 992px) {
    .btn-lg--auto {
      width: auto; } }

.btn-dark {
  background-color: #000; }

.tab {
  background-color: #fff;
  padding: 15px 30px 30px 30px;
  box-sizing: border-box;
  box-shadow: 0 0 14px 0 rgba(159, 159, 159, 0.5);
  border-radius: 4px;
  max-width: 350px; }
  .tab:after {
    content: '';
    clear: both;
    display: block; }
  .tab__items {
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    text-align: center;
    box-shadow: 0 1px 0 0 #c4cdd5;
    display: table;
    table-layout: fixed;
    width: 100%;
    position: relative; }
    .tab__items:after {
      content: '';
      clear: both;
      display: block; }
    .tab__items::-webkit-scrollbar {
      display: none; }
    .tab__items > a {
      color: #c4cdd5;
      font-weight: 500;
      font-size: 13px;
      text-transform: uppercase;
      padding: 15px 15px;
      transition: none;
      display: table-cell;
      float: none;
      width: 100%; }
      .tab__items > a.active {
        color: #008dff;
        border-bottom: 2px solid #008dff; }
  .tab__item-popup {
    white-space: initial !important;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: calc(100% + 20px);
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
    z-index: 1; }
    .tab__item-popup--left {
      left: -20px;
      right: calc(50% - 20px); }
    .tab__item-popup--right {
      left: calc(50% - 20px);
      right: -20px; }
    .tab__item-popup p {
      color: #fff;
      font-size: 14px;
      margin: 0; }
    .tab__item-popup:before {
      content: " ";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: -10px; }
  .tab__body {
    margin: 30px 0 0 0; }
    .tab__body .btn-lg {
      margin-bottom: 0; }
  .tab__item-body:not(.active) {
    display: none; }
  .tab__item-body--active {
    display: block !important; }
    .tab__item-body--active:after {
      content: '';
      clear: both;
      display: block; }

#loginCustomer p,
#loginGarage p {
  font-weight: 400; }

#register.active {
  display: block; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px; }
  .container:after {
    content: '';
    clear: both;
    display: block; }
  @media (min-width: 768px) {
    .container {
      width: 740px; } }
  @media (min-width: 992px) {
    .container {
      width: 960px; } }
  @media (min-width: 1140px) {
    .container {
      width: 1100px; } }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px; }
  .container-fluid:after {
    content: '';
    clear: both;
    display: block; }

.row {
  margin-left: -10px;
  margin-right: -10px; }
  .row:after {
    content: '';
    clear: both;
    display: block; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8 {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8 {
  float: left; }

.col-xs-1 {
  width: 12.5%; }

.col-xs-2 {
  width: 25%; }

.col-xs-3 {
  width: 37.5%; }

.col-xs-4 {
  width: 50%; }

.col-xs-5 {
  width: 62.5%; }

.col-xs-6 {
  width: 75%; }

.col-xs-7 {
  width: 87.5%; }

.col-xs-8 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 12.5%; }

.col-xs-pull-2 {
  right: 25%; }

.col-xs-pull-3 {
  right: 37.5%; }

.col-xs-pull-4 {
  right: 50%; }

.col-xs-pull-5 {
  right: 62.5%; }

.col-xs-pull-6 {
  right: 75%; }

.col-xs-pull-7 {
  right: 87.5%; }

.col-xs-pull-8 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 12.5%; }

.col-xs-push-2 {
  left: 25%; }

.col-xs-push-3 {
  left: 37.5%; }

.col-xs-push-4 {
  left: 50%; }

.col-xs-push-5 {
  left: 62.5%; }

.col-xs-push-6 {
  left: 75%; }

.col-xs-push-7 {
  left: 87.5%; }

.col-xs-push-8 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 12.5%; }

.col-xs-offset-2 {
  margin-left: 25%; }

.col-xs-offset-3 {
  margin-left: 37.5%; }

.col-xs-offset-4 {
  margin-left: 50%; }

.col-xs-offset-5 {
  margin-left: 62.5%; }

.col-xs-offset-6 {
  margin-left: 75%; }

.col-xs-offset-7 {
  margin-left: 87.5%; }

.col-xs-offset-8 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8 {
    float: left; }
  .col-sm-1 {
    width: 12.5%; }
  .col-sm-2 {
    width: 25%; }
  .col-sm-3 {
    width: 37.5%; }
  .col-sm-4 {
    width: 50%; }
  .col-sm-5 {
    width: 62.5%; }
  .col-sm-6 {
    width: 75%; }
  .col-sm-7 {
    width: 87.5%; }
  .col-sm-8 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 12.5%; }
  .col-sm-pull-2 {
    right: 25%; }
  .col-sm-pull-3 {
    right: 37.5%; }
  .col-sm-pull-4 {
    right: 50%; }
  .col-sm-pull-5 {
    right: 62.5%; }
  .col-sm-pull-6 {
    right: 75%; }
  .col-sm-pull-7 {
    right: 87.5%; }
  .col-sm-pull-8 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 12.5%; }
  .col-sm-push-2 {
    left: 25%; }
  .col-sm-push-3 {
    left: 37.5%; }
  .col-sm-push-4 {
    left: 50%; }
  .col-sm-push-5 {
    left: 62.5%; }
  .col-sm-push-6 {
    left: 75%; }
  .col-sm-push-7 {
    left: 87.5%; }
  .col-sm-push-8 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 12.5%; }
  .col-sm-offset-2 {
    margin-left: 25%; }
  .col-sm-offset-3 {
    margin-left: 37.5%; }
  .col-sm-offset-4 {
    margin-left: 50%; }
  .col-sm-offset-5 {
    margin-left: 62.5%; }
  .col-sm-offset-6 {
    margin-left: 75%; }
  .col-sm-offset-7 {
    margin-left: 87.5%; }
  .col-sm-offset-8 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8 {
    float: left; }
  .col-md-1 {
    width: 12.5%; }
  .col-md-2 {
    width: 25%; }
  .col-md-3 {
    width: 37.5%; }
  .col-md-4 {
    width: 50%; }
  .col-md-5 {
    width: 62.5%; }
  .col-md-6 {
    width: 75%; }
  .col-md-7 {
    width: 87.5%; }
  .col-md-8 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 12.5%; }
  .col-md-pull-2 {
    right: 25%; }
  .col-md-pull-3 {
    right: 37.5%; }
  .col-md-pull-4 {
    right: 50%; }
  .col-md-pull-5 {
    right: 62.5%; }
  .col-md-pull-6 {
    right: 75%; }
  .col-md-pull-7 {
    right: 87.5%; }
  .col-md-pull-8 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 12.5%; }
  .col-md-push-2 {
    left: 25%; }
  .col-md-push-3 {
    left: 37.5%; }
  .col-md-push-4 {
    left: 50%; }
  .col-md-push-5 {
    left: 62.5%; }
  .col-md-push-6 {
    left: 75%; }
  .col-md-push-7 {
    left: 87.5%; }
  .col-md-push-8 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 12.5%; }
  .col-md-offset-2 {
    margin-left: 25%; }
  .col-md-offset-3 {
    margin-left: 37.5%; }
  .col-md-offset-4 {
    margin-left: 50%; }
  .col-md-offset-5 {
    margin-left: 62.5%; }
  .col-md-offset-6 {
    margin-left: 75%; }
  .col-md-offset-7 {
    margin-left: 87.5%; }
  .col-md-offset-8 {
    margin-left: 100%; } }

@media (min-width: 1140px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8 {
    float: left; }
  .col-lg-1 {
    width: 12.5%; }
  .col-lg-2 {
    width: 25%; }
  .col-lg-3 {
    width: 37.5%; }
  .col-lg-4 {
    width: 50%; }
  .col-lg-5 {
    width: 62.5%; }
  .col-lg-6 {
    width: 75%; }
  .col-lg-7 {
    width: 87.5%; }
  .col-lg-8 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 12.5%; }
  .col-lg-pull-2 {
    right: 25%; }
  .col-lg-pull-3 {
    right: 37.5%; }
  .col-lg-pull-4 {
    right: 50%; }
  .col-lg-pull-5 {
    right: 62.5%; }
  .col-lg-pull-6 {
    right: 75%; }
  .col-lg-pull-7 {
    right: 87.5%; }
  .col-lg-pull-8 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 12.5%; }
  .col-lg-push-2 {
    left: 25%; }
  .col-lg-push-3 {
    left: 37.5%; }
  .col-lg-push-4 {
    left: 50%; }
  .col-lg-push-5 {
    left: 62.5%; }
  .col-lg-push-6 {
    left: 75%; }
  .col-lg-push-7 {
    left: 87.5%; }
  .col-lg-push-8 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 12.5%; }
  .col-lg-offset-2 {
    margin-left: 25%; }
  .col-lg-offset-3 {
    margin-left: 37.5%; }
  .col-lg-offset-4 {
    margin-left: 50%; }
  .col-lg-offset-5 {
    margin-left: 62.5%; }
  .col-lg-offset-6 {
    margin-left: 75%; }
  .col-lg-offset-7 {
    margin-left: 87.5%; }
  .col-lg-offset-8 {
    margin-left: 100%; } }

.mobile-padding {
  padding-left: 20px;
  padding-right: 20px; }

.no-side-paddings {
  padding-left: 0;
  padding-right: 0; }

.alert-danger {
  color: #e74c3c;
  border: 1px solid #e74c3c;
  background-color: #fbeae5; }

.alert-success {
  color: #2ecc71;
  border: 1px solid #2ecc71;
  background-color: #cbf3dc; }

.alert {
  width: 100%;
  min-height: 44px;
  height: auto;
  border-radius: 2px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  padding: 13px 58px 13px 13px;
  font-size: 14px;
  box-sizing: border-box;
  margin: 15px 0; }

.alert-btn-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 43px;
  width: 43px;
  text-align: center; }
  .alert-btn-close__icon {
    font-weight: bold;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block; }

form {
  background-color: #fff;
  border-radius: 4px; }
  form:after {
    content: '';
    clear: both;
    display: block; }

.form-holder {
  width: 100%; }
  @media (min-width: 992px) {
    .form-holder {
      width: 420px; } }

.form-title,
.form-secondary-cta {
  text-align: center;
  margin-bottom: 30px; }
  .form-title h5,
  .form-secondary-cta h5 {
    margin-top: 50px; }

@media (min-width: 992px) {
  .form-title {
    display: none; } }

.form-secondary-cta {
  color: #9b9b9b;
  margin-bottom: 60px; }

.form-sidebar {
  background-color: #000;
  border-radius: 5px;
  float: right;
  margin: 30px 0 30px -35px;
  width: 100%;
  display: none; }
  @media (min-width: 992px) {
    .form-sidebar {
      width: 350px;
      display: block; } }
  .form-sidebar img {
    width: 100%; }

.form-sidebar__labels {
  padding: 30px;
  border-radius: 5px; }

.form-sidebar__label {
  padding-left: 20px;
  position: relative;
  color: #FFF; }
  .form-sidebar__label i {
    position: absolute;
    top: 3px;
    left: 0;
    font-weight: bold;
    color: #1c9249; }

.form-sidebar-offset {
  margin-left: -35px; }

body,
html,
#root {
  height: 100%; }

#root {
  padding-top: 80px; }

@media screen and (max-width: 1140px) {
  body,
  html,
  #root {
    padding-top: 45px; } }

.m-0 {
  margin: 0 !important; }

@media (max-width: 992px) {
  .hide-mobile {
    display: none; } }

@media (min-width: 992px) {
  .hide-desktop {
    display: none; } }

@media (max-width: 992px) {
  .mobile-m--10 {
    margin: 0 -10px !important; } }

.w-box {
  border-radius: 4px;
  box-shadow: 0 2px 37px -11px rgba(210, 210, 210, 0.5), 0 2px 5px -1px rgba(171, 171, 171, 0.5);
  background-color: #FFF;
  margin-bottom: 20px; }
  .w-box:after {
    content: '';
    clear: both;
    display: block; }

.w-box-header {
  padding: 15px 20px; }
  .w-box-header__title {
    font-size: 16px;
    margin: 0;
    line-height: normal; }

.main {
  float: left;
  width: 100%; }
  .main:after {
    content: '';
    clear: both;
    display: block; }
  @media (min-width: 992px) {
    .main:not(.main--garage) {
      width: calc(100% - 375px);
      margin-left: 375px;
      height: 100%;
      padding: 20px; } }
  @media (min-width: 1140px) {
    .main {
      padding: 40px; } }
  .main__title {
    margin: 30px 10px 30px 10px; }
    @media (min-width: 992px) {
      .main__title {
        margin: 0 0 30px 0; } }
  @media (min-width: 1140px) {
    .main--garage {
      width: 1100px;
      margin-left: 375px;
      height: 100%;
      padding: 20px; } }
  .main--no-padding {
    padding: 0; }
  .main--center {
    margin: auto;
    float: none; }

.aside {
  width: 100%;
  background: #ffffff;
  border-right: 1px solid #d9d9d9; }
  @media (min-width: 992px) {
    .aside {
      width: 375px;
      overflow-y: scroll;
      height: calc(100% - 45px);
      position: fixed;
      padding-bottom: 15px; } }
  .aside > .step-indicator {
    padding: 30px 0 45px 0;
    margin: 0;
    background: #f8f9fa; }

.aside-wrapper {
  background-color: #fff;
  float: left;
  width: 100%; }

.aside-header,
.aside-accordion,
.aside-cta {
  float: left;
  width: 100%; }

.aside-header {
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
  padding: 25px 25px; }
  .aside-header__title p {
    color: #9b9b9b; }
  .aside-header__title--small-medium {
    margin: 0;
    font-size: 18px;
    font-family: "Eina", sans-serif;
    font-weight: 500; }
  .aside-header__title--small-medium + p {
    margin: 0;
    font-size: 15px; }

.aside-cta {
  padding: 25px 25px;
  background-color: #fff; }
  @media (min-width: 992px) {
    .aside-cta {
      display: none;
      border-right: 1px solid #d9d9d9;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 375px; } }

.tabs {
  margin: 30px 0;
  padding: 15px 30px 30px;
  border-radius: 4px; }
  .tabs .nav-tabs {
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    text-align: center;
    display: table;
    table-layout: fixed; }
    .tabs .nav-tabs .nav-item {
      cursor: pointer;
      -webkit-transition: none;
      transition: none;
      display: table-cell;
      float: none;
      width: 100%;
      color: #c4cdd5; }
    .tabs .nav-tabs .nav-link {
      font-size: 13px;
      display: block;
      padding: 15px;
      transition: none; }

.tab-default {
  background-color: #fff;
  box-shadow: 0 0 14px 0 rgba(159, 159, 159, 0.5); }
  .tab-default .nav-tabs {
    -webkit-box-shadow: 0 1px 0 0 #c4cdd5;
    box-shadow: 0 1px 0 0 #c4cdd5;
    width: 100%; }
    .tab-default .nav-tabs .nav-item {
      text-transform: uppercase;
      font-weight: 500; }
    .tab-default .nav-tabs .nav-link.active {
      color: #008dff;
      border-bottom: 2px solid #008dff; }
  .tab-default .tab-content {
    margin: 30px 0 0; }

.tab-label-centered .nav-tabs {
  margin: 0 auto; }
  .tab-label-centered .nav-tabs .nav-item {
    width: auto;
    font-weight: 300;
    padding: 0 10px; }
  .tab-label-centered .nav-tabs .nav-link {
    padding: 7px 14px;
    width: 100%;
    color: #999999; }
    .tab-label-centered .nav-tabs .nav-link.active {
      background: #008dff;
      color: #fff;
      border-radius: 50px;
      width: 100%; }

.tab-label-centered .tab-content {
  margin: 30px 0 0; }

@media (min-width: 992px) {
  .accordion {
    padding-bottom: 45px; } }

.accordion__item {
  border-bottom: 1px solid #d9d9d9; }
  .accordion__item:after {
    content: '';
    clear: both;
    display: block; }
  .accordion__item:last-child {
    border-bottom: 0; }
  @media (max-width: 992px) {
    .accordion__item--no-border {
      border-bottom: 0; } }
  @media (max-width: 992px) {
    .accordion__item--white-on-mobile {
      background-color: #fff; } }

.u-position-absolute {
  position: absolute; }

.u-position-relative {
  position: relative; }

.accordion__item--has-icon {
  position: relative; }

.accordion__arrow {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: -25px;
  margin-top: -10px;
  color: #000;
  transform: rotate(-180deg);
  font-size: 12px;
  top: 11px; }

[aria-expanded='true'] .accordion__arrow,
[aria-selected='true'] .accordion__arrow {
  transform: rotate(-180deg); }

[aria-expanded='true'] .accordion__arrow,
[aria-selected='true'] .accordion__arrow {
  transform: rotate(0deg); }

.accordion__arrow,
.accordion__arrow {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease; }

.accordion__title {
  padding: 20px 25px;
  cursor: pointer;
  outline: none; }
  @media (min-width: 992px) {
    .accordion__title {
      padding: 20px 25px 20px 0; } }
  .accordion__title > h5 {
    display: inline;
    margin: 0;
    font-size: 14px;
    font-weight: 500; }
    @media (min-width: 992px) {
      .accordion__title > h5 {
        font-size: 16px; } }

.accordion__body {
  padding: 0 25px 25px 25px; }
  .accordion__body:after {
    content: '';
    clear: both;
    display: block; }
  @media (min-width: 992px) {
    .accordion__body {
      padding: 0 0 25px 0; } }

.rect-shape,
.text-row,
.round-shape {
  background-color: #e8e8e8; }

.placeholder-container {
  margin: 15px 0; }
  .placeholder-container:after {
    content: '';
    clear: both;
    display: block; }
  .placeholder-container--white-bg {
    background-color: #fff; }

.placeholder-row {
  float: left;
  width: 100%; }
  .placeholder-row:after {
    content: '';
    clear: both;
    display: block; }
  .placeholder-row--paddings {
    padding: 15px 15px; }

.placeholder-separator {
  position: relative;
  height: 30px; }
  .placeholder-separator:after {
    background: #e8e8e8;
    content: " ";
    position: absolute;
    top: 0;
    width: 100%;
    height: 1px;
    bottom: 0;
    margin: auto; }

.circle {
  fill: none;
  stroke: #54d98c;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10; }

.circle-dash {
  fill: none;
  stroke: #54d98c;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-miterlimit: 10; }

.check {
  fill: none;
  stroke: #54d98c;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10; }

.check-dash {
  fill: none;
  stroke: #54d98c;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10; }

.check {
  stroke-dasharray: 60 100;
  animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s;
  -webkit-animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s;
  -moz-animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s;
  -o-animation: check 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards 0.15s;
  opacity: 0; }

@-webkit-keyframes check {
  from {
    stroke-dashoffset: 60;
    opacity: 1; }
  to {
    stroke-dashoffset: 00;
    opacity: 1; } }

@-moz-keyframes check {
  from {
    stroke-dashoffset: 60;
    opacity: 1; }
  to {
    stroke-dashoffset: 00;
    opacity: 1; } }

@keyframes check {
  from {
    stroke-dashoffset: 60;
    opacity: 1; }
  to {
    stroke-dashoffset: 00;
    opacity: 1; } }

.check-dash {
  stroke-dasharray: 10 100;
  animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards;
  -webkit-animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards;
  -moz-animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards;
  -o-animation: check-dash 1.2s cubic-bezier(0.5, 0, 0.6, 1) forwards; }

@-webkit-keyframes check-dash {
  from {
    stroke-dashoffset: 120; }
  to {
    stroke-dashoffset: 45; } }

@-moz-keyframes check-dash {
  from {
    stroke-dashoffset: 120; }
  to {
    stroke-dashoffset: 45; } }

@keyframes check-dash {
  from {
    stroke-dashoffset: 120; }
  to {
    stroke-dashoffset: 45; } }

.circle {
  stroke-dasharray: 300 300;
  animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s;
  -webkit-animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s;
  -moz-animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s;
  -o-animation: circle 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.15s;
  opacity: 0; }

@-webkit-keyframes circle {
  from {
    stroke-dashoffset: 300;
    opacity: 1; }
  to {
    stroke-dashoffset: 0;
    opacity: 1; } }

@-moz-keyframes circle {
  from {
    stroke-dashoffset: 300;
    opacity: 1; }
  to {
    stroke-dashoffset: 0;
    opacity: 1; } }

@keyframes circle {
  from {
    stroke-dashoffset: 300;
    opacity: 1; }
  to {
    stroke-dashoffset: 0;
    opacity: 1; } }

.circle-dash {
  stroke-dasharray: 10 300;
  animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
  -webkit-animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
  -moz-animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
  -o-animation: circledash 1.5s cubic-bezier(0.5, 0, 0.5, 1) forwards 0.05s;
  opacity: 0; }

@-webkit-keyframes circledash {
  from {
    stroke-dashoffset: 320;
    opacity: 1; }
  to {
    stroke-dashoffset: 20;
    opacity: 1; } }

@-moz-keyframes circledash {
  from {
    stroke-dashoffset: 320;
    opacity: 1; }
  to {
    stroke-dashoffset: 20;
    opacity: 1; } }

@keyframes circledash {
  from {
    stroke-dashoffset: 320;
    opacity: 1; }
  to {
    stroke-dashoffset: 20;
    opacity: 1; } }

div.input-range {
  margin-bottom: 30px;
  float: left;
  width: 100%; }

div.input-range__slider {
  box-shadow: 2px 2px 7px 0 rgba(219, 219, 219, 0.5);
  border: solid 5px #f7f7f7;
  background-color: #008dff;
  width: 24px;
  height: 24px; }

div.input-range__track {
  height: 2px; }

div.input-range__track--active {
  background-color: #008dff; }

span.input-range__label {
  display: none; }

span.input-range__label--min,
span.input-range__label--max {
  bottom: 30px; }

span.input-range__label--min {
  left: 0; }
  span.input-range__label--min .input-range__label-container {
    left: 0; }

span.input-range__label--max {
  right: 0; }
  span.input-range__label--max .input-range__label-container {
    left: 0; }

.input-range__container {
  float: left;
  width: 100%; }
  .input-range__container--disabled {
    opacity: .5;
    pointer-events: none; }

.input-range__header > p.title {
  color: #919eab;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
  margin-top: 15px; }

.input-range__header > p.value {
  font-weight: 500;
  font-size: 12px;
  color: #000; }

@media (min-width: 992px) {
  .ctg-filter-dropdown-enter {
    height: 150px !important;
    transform: translate(20px, 20px);
    width: calc(100% - 40px); }
  .ctg-filter-dropdown-enter-active {
    transition: 450ms ease-in; }
  .ctg-filter-dropdown-enter.ctg-filter-dropdown-enter-active {
    height: 300px !important;
    transform: translate(-15px, -15px);
    width: calc(100% + 30px); }
  .ctg-filter-dropdown-leave-active {
    transition: 300ms ease-in; }
  .ctg-filter-dropdown-leave {
    overflow: scroll;
    height: 300px !important;
    transform: translate(-15px, -15px);
    width: 100%; }
  .ctg-filter-dropdown-leave.ctg-filter-dropdown-leave-active {
    height: 250px !important;
    transform: translate(-5px, -5px);
    width: calc(100% + 10px); } }

div.react-datepicker {
  padding: 5px 5px;
  border-radius: 12px;
  max-width: 100%; }
  @media (max-width: 768px) {
    div.react-datepicker {
      top: 50px; } }
  @media (max-width: 375px) {
    div.react-datepicker {
      font-size: 14px; } }
  @media (min-width: 768px) {
    div.react-datepicker {
      box-shadow: 0 0 14px 0 rgba(159, 159, 159, 0.5); } }

div.react-datepicker__current-month {
  background: #fafafa;
  width: 90%;
  padding: 8px 8px;
  color: #000;
  font-weight: normal;
  margin: 10px auto 10px auto;
  border-radius: 12px; }
  @media (max-width: 375px) {
    div.react-datepicker__current-month {
      margin: 5px auto;
      padding: 5px 0; } }

@media (max-width: 768px) {
  .react-datepicker__month-container {
    float: none !important; } }

.react-datepicker__header {
  border-bottom: 0; }

div.react-datepicker {
  border: 0; }

div.react-datepicker__triangle {
  display: none; }

div.react-datepicker {
  font-family: "Atto", sans-serif;
  font-size: 16px; }

div.react-datepicker__header {
  text-align: center;
  background-color: transparent;
  border-bottom: 0; }

button.react-datepicker__navigation {
  top: 26px;
  outline: none; }

button.react-datepicker__navigation--previous,
button.react-datepicker__navigation--next {
  border: solid #959595;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  top: 40px; }

button.react-datepicker__navigation--previous {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 11%; }

button.react-datepicker__navigation--next {
  right: 11%;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); }

div.react-datepicker__day--selected {
  background-color: #008dff; }

div.react-datepicker-popper {
  top: 50% !important;
  transform: translateY(-50%) !important;
  right: 0;
  left: 0;
  position: fixed !important;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  height: 100%; }
  @media (min-width: 768px) {
    div.react-datepicker-popper {
      position: absolute !important;
      transform: none !important;
      top: 65px !important;
      right: 0 !important;
      left: auto !important;
      background: none;
      height: auto; } }

div.react-datepicker__input-container input[readonly][readonly] {
  border: solid 1px #e5eced !important;
  cursor: pointer; }

div.react-datepicker__day-name,
div.react-datepicker__day {
  width: 38px;
  height: 38px;
  line-height: 2.5;
  margin: 0 2px; }
  div.react-datepicker__day-name:hover,
  div.react-datepicker__day:hover {
    border-radius: 12px; }
  @media (max-width: 375px) {
    div.react-datepicker__day-name,
    div.react-datepicker__day {
      width: 30px;
      height: 30px;
      line-height: 2;
      margin: 0 1px; } }

div.react-datepicker__day--keyboard-selected,
div.react-datepicker__day--selected {
  background-color: #754ceb;
  border-radius: 12px; }
  div.react-datepicker__day--keyboard-selected:hover,
  div.react-datepicker__day--selected:hover {
    background-color: #6335e8;
    border-radius: 12px; }

body,
html {
  padding: 0;
  margin: 0; }
