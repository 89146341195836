@import './variables';

form {
    @include clearfix;
    background-color: #fff;
    border-radius: 4px;
}

.form-holder {
    width: 100%;

    @media (min-width: $screen-md-min) {
        width: 420px;
    }
}

.form-title,
.form-secondary-cta {
    text-align: center;
    margin-bottom: 30px;
    h5{
        margin-top:50px;
    }
}

.form-title{
    @media (min-width: $screen-md-min) {
        display: none;
    }
}

.form-secondary-cta {
    color: #9b9b9b;
    margin-bottom:60px;
}

.form-sidebar{
    background-color: #000;
    border-radius: 5px;
    float: right;
    margin: 30px 0 30px -35px;
    width: 100%;
    display: none;

    @media (min-width: $screen-md-min) {
        width: 350px;
        display: block;
    }

    img{
        width: 100%;
    }
}

.form-sidebar__labels{
    padding: 30px;
    border-radius: 5px;
}

.form-sidebar__label{
    padding-left: 20px;
    position: relative;
    color: #FFF;

    i{
        position: absolute;
        top: 3px;
        left: 0;
        font-weight: bold;
        color: $brand-success;
    }
}
.form-sidebar-offset{
    margin-left: -35px;
}
