@import "../../../assets/styles/base/variables";

.slide-testemonial {
    float: left;
    display: block;
    width: 100%;

    .slick-slider.slick-initialized{
        padding-bottom: 25px;
    }

    > h5 {
        margin-bottom: 30px;
        font-family: $font-eina;
        font-weight: 500;
    }

    .slick-dots{
        position: relative;
        bottom: 0;
    }

    .slick-dots li button:before {
        font-size: 46px;
        color: gray;
        margin: 0;
    }

    .slick-dots li.slick-active button:before {
        opacity: .6;
    }

    .slick-dots li {
        margin: 0;
    }

    .slick-slide {
        padding: 15px 25px;
        margin: 0 10px 10px 0;
    }

    .slick-track {
        display: flex !important;
    }

    .slick-list{
        transition: height 0.5s;
    }
    /*.slick-slide {
        height: inherit !important;
    }*/

    .slick-slide div:nth-child(1) {
        outline: none;
    }

    /* end of reset default styles */

    &__header {
    }

    &__avatar,
    &__rating {
        float: left;
    }

    &__avatar {
        margin-right: 15px;

        > img {
            width: 40px;
            height: 40px;
            border-radius: 4px;
        }
    }

    &__rating {

        > h5 {
            font-size: 18px;
            margin: 0;
        }

        > img {
            width: 18px;
            float: left;
            margin-right: 5px;
            height: 18px;
        }

        > span {
            font-size: 18px;
            color: #ffe869;
            margin-right: 2px;
        }
    }

    &__body {
        > p {
            margin: 15px 0;
            font-size: 14px;
        }
    }
}
