@import './../base/variables';

.tabs {
    margin: 30px 0;
    padding: 15px 30px 30px;
    border-radius: 4px;

    .nav-tabs {
        overflow-y: hidden;
        white-space: nowrap;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        text-align: center;
        display: table;
        table-layout: fixed;

        .nav-item {
            cursor: pointer;
            -webkit-transition: none;
            transition: none;
            display: table-cell;
            float: none;
            width: 100%;
            color: #c4cdd5;
        }

        .nav-link {
            font-size: 13px;
            display: block;
            padding: 15px;
            transition: none;
        }
    }
}

.tab-default {
    background-color: #fff;
    box-shadow: 0 0 14px 0 rgba(159, 159, 159, 0.5);

    .nav-tabs {
        -webkit-box-shadow: 0 1px 0 0 #c4cdd5;
        box-shadow: 0 1px 0 0 #c4cdd5;
        width: 100%;

        .nav-item {
            text-transform: uppercase;
            font-weight: 500;
        }

        .nav-link {
            &.active {
                color: #008dff;
                border-bottom: 2px solid #008dff;
            }
        }
    }

    .tab-content {
        margin: 30px 0 0;
    }
}

.tab-label-centered {
    .nav-tabs {
        margin: 0 auto;

        .nav-item {
            width: auto;
            font-weight: 300;
            padding: 0 10px;
        }

        .nav-link {
            padding: 7px 14px;
            width: 100%;
            color: #999999;

            &.active {
                background: $brand-primary;
                color: #fff;
                border-radius: 50px;
                width: 100%;
            }
        }
    }

    .tab-content {
        margin: 30px 0 0;
    }
}
