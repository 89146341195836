@import '../../../assets/styles/base/variables';

.car-detail {
    padding-bottom: 20px;

    @media (min-width: $screen-md-min) {
        padding-bottom: 0;
    }

    &__item {
        margin-bottom: 15px;
        
        &--key {
            text-transform: uppercase;
            margin-bottom: 0;
        }

        &--value {
            margin-top: -5px;
        }

        &--link {
            margin-top: 20px;

            > a {
                font-size: 12px;
                font-weight: 700;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item-logo {
        width: 60px;
        height: 60px;
        object-fit: contain;
    }
}
