@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.search-item-container {
    float: left;
    width: 100%;
    @include clearfix;
}

.search-item-extra,
.search-item {
    position: relative;
    display: table;
    border-radius: 4px;
    margin: 5px 0;
    cursor: pointer;
    word-break: break-word;
    float: left;
}

.search-item-extra {
    padding: 12px 0;

    > span {
        font-size: 20px;
        position: relative;
    }
}

.search-item {
    padding: 12px 12px;
    box-shadow: 0 1px 10px 0 rgba(13, 13, 13, 0.2);
    max-width: 90%;
    margin-bottom: 10px;
    border:1px solid transparent;

    &--selected {
        border: 1px solid $brand-primary;
        box-shadow: 0 1px 10px 0 rgba(0, 141, 255, 0.2);
    }

    &--not-exist {
        pointer-events: none;
        cursor: default;

        p {
            opacity: 0.8;
        }
    }

    &--disabled {
        pointer-events: none;
    }

    &:nth-child(odd) {
        margin-right: 10px;
    }

    > p {
        margin: 0;
        color: #000;
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
    }

    &__icon {
        margin-right: 10px;
        position: relative;
        top: 1px;

        &--highlighted {
            color: $brand-primary;
        }
    }
}

.search-item-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.search-item-tooltip {
    position: relative;
    color: $text-lighter;
    cursor: pointer;

    &__text {
        color: #FFFFFF;
        font-size: 13px;
        visibility: hidden;
        padding: 5px 10px;
        background-color: rgba(0, 0, 0, 0.8);
        text-align: center;
        border-radius: 6px;
        position: absolute;
        z-index: 10;
        min-width: 150px;
        font-weight: 500;
        line-height: normal;

        &:before{
            content: '';
            position: absolute;
            top: -4px;
            right: 15px;
            width: 7px;
            height: 7px;
            transform: rotate(45deg);
            background: linear-gradient(to top left, transparent 50%, rgba(0,0,0,0.8) 50%);
        }
    }

    &:hover &__text{
        visibility: visible;
    }

    &--top {
        top: 120%;
        right: -10px;
    }
}
