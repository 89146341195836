@import '../../../assets/styles/base/variables';

.flash-message {
    padding: 25px 20px;
    margin-bottom: 15px;

    > p {
        font-size: 18px;
        margin: 0;
    }

    &--error {
        color: $alert-danger-primary;
        background-color: $alert-danger-secondary;
        border: 1px solid $alert-danger-primary;
        border-radius: 4px;
    }

    &--success {
        color: $alert-success-primary;
        background-color: $alert-success-secondary;
        border: 1px solid $alert-success-primary;
        border-radius: 4px;
    }

}
