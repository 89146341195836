@import "../../../../assets/styles/base/variables";

.selected-service {
    border-radius: 4px;
    background-color: #effaff;
    font-weight: 500;
    color: #345570;
    padding: 6px;
    display: inline-block;
    margin: 0 10px 10px 0;
    cursor: pointer;
    font-size: 12px;
    position: relative;

    .icon {
        margin-left: 10px;
        font-weight: 700;
    }
}
