@import "../../../assets/styles/base/variables";

@media (min-width: 992px) {
    #root .form-holder{
        float: none;
    }

    #root .iframe-container .form-holder {
        float: right;
    }
}

.form-container{
    background-color: #FFF;
    margin: 30px 0;
    padding: 30px 30px 30px;
    border-radius: 4px;
    box-shadow: 0 3px 10px rgba(159, 159, 159, 0.28);

    &__header{
        padding-bottom: 10px;

        &--border-bottom{
            margin-bottom: 40px;
            border-bottom: 1px solid #ebebeb;
        }
    }

    &__message{
        font-size: $font-size;
        color: $text-gray;
        font-family: $font-atto;
        font-weight: 600;
        margin-bottom: 30px;
        position: relative;
        padding-left: 30px;

        i{
            font-size: $font-size-4;
            vertical-align: sub;
            position: absolute;
            left: 0;
            top: 0;
        }

        &--success i{
            color: $btn-success;
        }
        &--warning i{
            color: $brand-warning;
        }

    }

    &__progress-container{
        position: relative;

        &__line{
            height: 1px;
            background-color: #ddd;
        }

        &__bar{
            position: absolute;
            left: 0;
            top: -2px;
            height: 4px;
            background-color: $brand-primary;
            border-radius: 5px;
            width: 20%;
            transition: width 0.3s;
        }
    }
}

.car-data-complete{
    border: 1px solid #ebebeb;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(159, 159, 159, 0.28);
    padding: 20px;

    &__logo{
        width: 55px;
        height: 55px;
        position: relative;
        border: 1px solid #ebebeb;
        border-radius: 5px;

        img{
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            left: 50%;
            width: 40px;
            height: 40px;
            object-fit: contain;
        }
    }

    &__info{
        font-family: $font-atto;
        font-weight: 600;
        color: $text-gray;
        font-size: $font-size;
        margin-top: 8px;
    }
}

.licence-plate-loader{
    text-align: center;
    padding: 80px 30px 0;

    &__animation{
        margin-bottom: 100px;

        img{
            width: 20px;
            vertical-align: bottom;
            margin-right: 5px;
        }

        &__label{
            font-family: $font-atto;
            font-weight: 600;
            color: $text-lighter;
            font-size: $font-size;
        }
    }

}

.button-funnel {
    position: relative;

    > .loader-button-funnel {
        //background: red;
    }
}

.back-btn {
    width: 35px;
    height: 35px;
    padding: 0;
    font-size: 12px;

    i {
        position: relative;
        top: 1px;
        left: -1px;
        font-weight: bold;
        line-height: 30px;
    }
}
/* dark mode */
#root-copy {
    @media (min-width: $screen-md-min) {
        top: 0;
        position: absolute;
        left: 85%;
    }

    .tab-default {
        background-color: #212b36;

        form {
            background: transparent;
        }

        .dd-container label,
        .input-control label {
            color: #fff;
        }

        .dd-select--error {
            color: red;
        }

        .dd-select--disabled {
            color: rgba(33, 43, 54, 0.7);
            background-color: #c4cdd5;
        }
    }
}

.source-provider{
    font-size: 12px;
    margin-top: 5px;
    color: $text-lighter;
    font-weight: 500;
}
