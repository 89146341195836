@import '../../../assets/styles/base/mixins';
@import '../../../assets/styles/base/variables';

.checkout-payment {
    float: left;
    width: 100%;
    box-shadow: 0 0 14px 0 hsla(0,0%,62.4%,.5);
    border-radius: 4px;
    padding: 20px 20px;
    background-color: #fff;
    margin: 40px 0;
    position: relative;

    &__text {
        width: 100%;
        float: left;

        > span > p {
            padding: 0 20px;
            color: #b0b0b0;
        }

        > p.title {
            color: #000;
            padding: 0;
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 18px;
        }

        @media (min-width: $screen-md-min) {
            width: 65%;
        }
    }

    &__animation {
        display: none;

        @media (min-width: $screen-md-min) {
            display: block;
            width: 35%;
            position: absolute;
            right: 30px;
            bottom: 30px;
            padding-left: 30px;
        }
    }

    &--garage-container {
        display: none;

        @media (min-width: $screen-md-min) {
            display: block;
        }
    }

    &--accordion {
        @media (min-width: $screen-md-min) {
            display: none;
        }
    }

}

.input-group-voucher{

    .input-control{
        display: inline-block;
        width: calc(100% - 45px);

        input{
            border-radius: 4px 0 0 4px;
            border-right: none;
        }
    }

    .btn{
        display: inline-block;
        border-radius: 0 4px 4px 0;
    }

}
