@import "../../../../assets/styles/base/variables";

.additional-service-list {
    padding: 0 0 30px 0;
    
    @media (min-width: $screen-md-min) {
        padding: 0;
        max-height: 380px;
        overflow-y: scroll;
    }

    &--push-top {
        padding: 50px 0 30px 0;
    }
}

.additional-service-uncheck-large {
    float: left;
    width: 100%;
    text-align: center;
    margin: 20px 0;
    border-top: 1px solid #e2e7ed;
    
    button {
        margin: 25px auto 0 auto;
    }
    
    @media (min-width: $screen-md-min) {
        margin: 0;
        
        button {
            margin-top: 50px;
        }
    }
    
}
