@import "variables";

label {
    font-weight: normal;
    margin-bottom: 14px;
    display: inline-block;
    color: $label-gray;

    a {
        font-size: $font-size;
    }
}

.form-group {
    label {
        width: 85%;
        word-wrap: break-word;
    }
}

.form-response {
    text-align: center;
    display: none;
    position: relative;

    > div {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &__animation {
        width: 70px !important;
        height: 70px !important;
    }

    &__message {
        color: #919eab;
    }
}

form {
    @include clearfix;
}

select,
input[type="text"],
input[type="email"],
input[type="number"],
input[readonly],
input[type="password"],
input[type="tel"],
textarea,
select::placeholder,
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[readonly]::placeholder,
input[type="tel"]::placeholder,
textarea::placeholder {
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
}

select,
input[type="text"],
input[type="email"],
input[type="number"],
input[readonly],
input[type="password"],
input[type="tel"],
textarea {
    display: block;
    width: 100%;
    margin: $form-margin;
    border-radius: $border-radius-2;
    background-color: #ffffff;
    border: 1px solid $border-color;
    min-height: 36px;
    height: 44px;
    padding: 11px 10px 10px 10px;
    outline: 0;

    &[disabled] {
        background-color: $form-input-disabled;
        opacity: 0.5;
        cursor: not-allowed;
    }

    &[readonly] {
        cursor: default;
    }

    &:not(.input-error):focus {
        border: 1px solid $brand-primary;
    }

    &::placeholder {
        color: $secondary-gray;
    }

    &:-ms-input-placeholder {
        color: $secondary-gray;
    }
}

textarea {
    display: block;
    width: 100%;
    padding: $form-padding;
    margin: 10px 0 !important;
    color: #000 !important;
    font-weight: bold !important;
    font-size: 15px !important;
    border: 1px solid $border-color;
    font-family: $font-default;
    border-radius: $border-radius-2;
}

input.input-error,
textarea.input-error {
    border: 1px solid $alert-danger-primary;
    background-color: $alert-danger-secondary;
}

input.input-suffix {
    padding-right: 50px; // 40px icon + 10px margin
}

.input-prefix,
.input-suffix {
    position: relative;
    font-family: "FontAwesome", sans-serif;
    font-size: 21px;
    float: left;
    width: 100%;

    &::before,
    &::after {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        text-align: center;
        opacity: 0.5;
        padding-top: 8px;
    }
}

.input-prefix {
    & input:first-child {
        padding-left: 40px;
    }

    &::before {
        left: 0;
    }

    &--dollar::before {
        content: "\f155";
    }

    &--search::before {
        content: "\f002";
    }

    &--envelope::before {
        content: "\f0e0";
    }
}

.input-suffix {
    & input:first-child {
        padding-right: 40px;
    }

    &::after {
        right: 0;
    }

    &--kg::after {
        content: "kg";
    }

    &--close::after {
        content: "\f057";
    }
}

.form-inline {
    position: relative;
    float: left;
    width: 100%;
    margin: 10px 0;

    input {
        margin: 0;
    }
}

.icpr {
    padding-right: 15px;
}

.icpl {
    padding-left: 15px;
}

.input-control {
    position: relative;
    float: left;
    width: 100%;
    margin: 10px 0;

    &--social {
        text-align: center;
    }

    &--half {
        width: 50%;
        float: left;
    }

    input,
    textarea {
        margin: 5px 0 0 0;
    }

    label {
        font-weight: 500;
        font-size: 14px;
    }
}

.input-control-icons {
    float: left;
    width: 100%;
    margin: 10px 0;
    position: relative;

    &.left-padding {
        > input {
            padding: 10px 10px 10px 40px;
        }
    }

    &.right-padding {
        > input {
            padding: 10px 40px 10px 10px;
        }
    }

    &.both-padding {
        > input {
            padding: 10px 40px 10px 40px;
        }
    }

    > input {
        margin: 0;
    }
}

.input-prefix {
    width: 42px;
    height: 42px;
    position: absolute;
    text-align: center;
    top: 0;
    opacity: 0.5;
    pointer-events: none;
    cursor: default;

    &.clickable {
        pointer-events: initial;
        cursor: pointer;
    }

    &:hover {
        opacity: 1;
    }

    &--right {
        right: 0;
    }

    &--left {
        left: 0;
    }

    &::before {
        top: 50%;
        transform: translateY(-50%);
        margin: 2px auto 0 auto;
        text-align: center;
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
    }
}

.customCheckbox {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 4px;
    margin-right: 5px;
    overflow: hidden;
    border: 2px solid #c4cdd5;
    top: 2px;

    &.customCheckboxChecked {
        border: 2px solid $brand-primary;
        border-radius: 4px;

        > span {
            display: block;
        }
    }

    input {
        opacity: 0;
        cursor: pointer;
        z-index: 5;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }

    span {
        color: $brand-primary;
        display: none;
        text-align: center;
        line-height: 17px;
        font-size: 12px;
    }
}

input[readonly] {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    user-select: none;

    &:focus {
        border: 0 !important;
    }
}

input[type=file] {
    display: none;
}

.garage-phone-input {
    margin-top: 15px;
}

.has-error {
    font-size: 12px;
    color: $brand-danger;
    margin-top: 0;
    display: inline-block;
    position: absolute;
    text-align: right;
    width: 100%;
}

.has-success {
    font-size: 12px;
    color: $brand-success;
    margin-top: 0;
    display: inline-block;
    position: absolute;
    text-align: right;
    width: 100%;
}


.success-text {
    color: $brand-success;
}

.is-invalid {
    > input {
        border: 1px solid #e74c3c;
        background-color: #fbeae5;

        &:focus {
            border: 1px solid #e74c3c !important;
            background-color: #fbeae5;
        }
    }

    > small {
        display: inline-block;
        width: 100%;
        text-align: right;
    }
}
