@import '../../../assets/styles/base/variables';

.social-media-login{
    text-align: center;
}

.btn-social-media {
    background-color: #FFF;
    font-size: $font-size;
    color: $text-light-dark;
    border: $border;
    margin: 5px 0;
    width: 230px;
    padding: 8px 12px !important;
    text-align: left;

    img{
        vertical-align: sub;
        margin-right: 10px;
        width: 18px;
    }

    &:not([disabled]):hover{
        background-color: #FFF;
        color: #919eab;
    }
}
