@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.label {
    border: 0;
    margin: 0 5px 5px 0;
    padding: 8px 12px;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    outline: 0 !important;
    box-sizing: border-box;
    font-weight: 500;
    transition: 0.2s ease-in;
    cursor: default;
    line-height: .9;
    position: relative;
    top: -2px;

    &[disabled] {
        cursor: not-allowed;

        &:hover,
        &:active,
        &:focus {
            color: inherit;
            outline: 0 !important;
            background: $btn-disabled;
        }
    }

    &__icon {
        display: inline;
        margin-right: 5px;

        /* icon */
        > span {
            font-weight: bold;
            position: relative;
            top: 1px;
        }
    }

    /* sizes */
    &--md {
        font-size: 12px;
        font-weight: 500;
    }

    /* variations */
    &--success {
        @include button-bg($label-success-secondary, $label-success-primary);

        &:not([disabled]):hover {
            background-color: $label-success-secondary;
        }

        [class^='icon'] {
            color: $label-success-lighten;
        }
    }

    &--warning {
        @include button-bg($label-warning-secondary, $label-warning-primary);

        &:not([disabled]):hover {
            background-color: $label-warning-secondary;
        }

        [class^='icon'] {
            color: $label-warning-lighten;
        }
    }

    &--info {
        @include button-bg($label-info-secondary, $label-info-primary);

        &:not([disabled]):hover {
            background-color: $label-info-secondary;
        }

        [class^='icon'] {
            color: $label-info-lighten;
        }
    }
}
