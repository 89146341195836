@import '../../../assets/styles/base/variables';
@import '../../../assets/styles/base/mixins';

.garage-item-container {
    box-shadow: 0 2px 24px -3px rgba(211, 211, 211, 0.5), 0 2px 5px -1px rgba(171, 171, 171, 0.5);
    padding: 10px 0 0 0;
    border-top: 1px solid #f8f8f8;
    background-color: #fff;
    margin-bottom: 15px;
    position: relative;
    border-radius: 4px;

    @media (min-width: $screen-md-min) {
        min-height: 156px;
    }

    @media (min-width: $screen-lg-min) {
        padding: 20px 0 0 0;
    }

    &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &--recommended {
        background-color: #f6fffa;
        padding: 50px 0 10px 0;

        @media (min-width: $screen-lg-min) {
            padding: 50px 20px 20px 20px;
        }
    }
}

.garage-item,
.garage-item-options {
    @include clearfix;
}

.garage-best-deal {
    background-color: #2ecc71;
    color: #fff;
    padding: 5px 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &__left,
    &__right {
        width: 50%;
        float: left;

        > p {
            font-size: 15px;
            margin: 0;
            line-height: normal;
            display: inline-block;
        }
    }

    &__right {
        text-align: right;
    }
}

.garage-item {
    @media (min-width: $screen-lg-min) {
        display: table;
    }

    &__logo {
        width: 90px;
        height: 90px;
        float: left;
        margin: 0 10px 0 0;
        position: relative;
        border: 1px solid #eaeaea;
        border-radius: 4px;

        @media (min-width: $screen-lg-min) {
            width: 85px;
            height: 85px;
        }

        > img {
            position: absolute;
            width: 100%;
            padding: 10px 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.garage-item-detail {
    width: calc(100% - 100px);
    margin-left: 100px;

    @media (min-width: $screen-lg-min) {
        padding-left: 20px;
        display: table-cell;
        vertical-align: middle;
        width: 100%;
    }

    .label.label--md {
        font-size: 10px;
    }
}

.garage-item-detail-name {
    &__garage {
        margin-right: 10px;
    }

    h5 {
        color: #637381;
        font-size: 18px;
        margin: 0;
        font-family: $font-eina;
        font-weight: 700;
    }

    p {
        font-size: 14px;
        margin: -3px 0 0 0;
        line-height: 16px;
        color: #9b9b9b;
    }
}

.garage-item-detail-extra {
    margin: 10px 0 10px 0;
    @include clearfix;
    
    &.topMarginModification {
        margin: -5px 0 0 0;
    }
}

.garage-item-options {
    text-align: right;
    border-top: 1px solid #eaeaea;
    margin-top: 40px;
    padding: 0;

    @media (min-width: $screen-lg-min) {
        margin: 0;
        border: none;
    }


    .arrow-xs {
        padding: 0;
        @media (min-width: $screen-lg-min) {
            display: none;
        }
    }
    
    .arrow-lg {
        display: none;
        
        @media (min-width: $screen-lg-min) {
            display: block;
        }
    }

    button {
        margin: 10px 0;

        > .icon {
            position: relative;
            top: 1px;
            left: 2px;
        }
    }

    @media (min-width: $screen-lg-min) {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        padding: 0;

        > .arrow-lg {
            display: table-cell;
            vertical-align: middle;
            margin: 0;
            float: right;
        }
    }

    &__price {
        margin: 0 15px;
        color: #919eab;
        font-size: 15px;
        font-weight: 700;
        
        &.collapsed:after,
        &.expanded:after {
            font-family: "carhelper";
            margin-left: 10px;
            font-size: 15px;
        }
        
        &.collapsed:after {
            content: "\e913";
        }
        
        &.expanded:after {
            content: "\e916";
        }

        @media (min-width: $screen-lg-min) {
            display: inline-block;
            margin: 0;
            width: 100%;
        }

        &--has-discount{
            text-decoration: line-through;
            color: #9eacb9;
            font-size: $font-size-4;
        }

        &--discount{
            font-size: $font-size-4;
            color: $brand-primary;
        }
        &--discount-value{
            font-size: 14px;
            color: $brand-danger;
        }

    }

    &__cta {
        padding: 15px 20px;
        margin: 30px 0 15px 0;

        @media (min-width: $screen-lg-min) {
            margin: 0;
        }
    }
}

.garage-item-container-disabled{
    background-color: #fafafa;

    .garage-item-detail-extra{
        font-size: 14px;
        color: #919eab;
        font-weight:bold;
    }

    .garage-item-options{
        min-height: auto;
        height: auto;
    }
}

.garage-item-collapsed {
    cursor: pointer;
    padding: 10px 10px 0 10px;

    @media (min-width: $screen-lg-min) {
        position: relative;
        padding: 10px 20px;
    }
}

.garage-item-expanded {
    padding: 0;
    transition: 250ms ease-in;
    
    @media (min-width: $screen-lg-min) {
        border-top: 1px solid #f8f9fa;
        float: left;
    }
    
    &__date-book,
    &__drop-off {
        padding: 0 10px;
    }
    
    &__drop-off {
        @media (min-width: $screen-lg-min) {
            //padding-left: 30px;
            margin-top: 15px;
            padding: 30px 10px 30px 30px;
        }
    }
    
    &__drop-off-title {
       font-size: 16px;
       font-weight: 700;
       margin-bottom: 5px !important;
    
        @media (min-width: $screen-md-min) {
            margin-bottom: 20px !important;
        }
    }
    
    &__drop-off-text {
        color: #919eab;
        line-height: 1.33;
        font-weight: 500;
        font-size: 14px !important;
    }
    
    &__date-book {
        margin-top: 30px;

        @media (min-width: $screen-lg-min) {
            margin-top: 0 !important;
            border-top: 1px solid #f8f9fa;
            padding: 20px 20px 10px 20px;
            border-left: 1px solid #f8f9fa;
            border-top: 0;
        }
    }
}

#menu #list {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    background: #d5d5d5;
}

#menu:hover #list {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
}

.example-appear {
    max-height: 0;
    overflow: hidden;
}

.example-appear.example-appear-active,
.example-enter-done {
    max-height: 500px;
    transition: max-height 300ms ease-out;
}

.example-leave-active {
    background-color: green;
    max-height: 0;
    transition: max-height 250ms ease-out;
}

.pgid-container {
    padding: 5px 10px 10px 10px;
    margin: 0 0 10px 0;
    border-radius: 4px;
    box-shadow: 0 2px 24px -3px rgba(211, 211, 211, 0.5), 0 2px 5px -1px rgba(171, 171, 171, 0.5);
    background-color: rgba(255, 255, 255, 0);
    position: relative;

    @media (min-width: $screen-lg-min) {
        min-height: 156px;
    }

    h5 {
        color: #637381;
        margin-bottom: 5px;
        font-size: 16px;
    }

    p {
        color: #919eab;
        font-size: 12px;
        line-height: 16px;
    }
}

.pgid-avatar {
    display: none;

    @media (min-width: $screen-md-min) {
        display: block;
        width: 90px;
        height: 90px;
        float: left;
        margin: 10px 10px 0 0;
        position: relative;
        border: 1px solid #eaeaea;
        border-radius: 4px;

        img {
            position: absolute;
            width: 100%;
            padding: 10px 10px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    @media (min-width: $screen-lg-min) {
        width: 85px;
        height: 85px;
        margin: 10px 30px 0 10px;
    }
}

.pgid-details {
    @media (min-width: $screen-lg-min) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.pgid-image {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    text-align: right;

    img {
        padding: 10px 10px;
    }
}
