@import '../../../assets/styles/base/mixins';
@import '../../../assets/styles/base/variables';

.checkout-payment {
    float: left;
    width: 100%;
    box-shadow: 0 0 14px 0 hsla(0,0%,62.4%,.5);
    border-radius: 4px;
    padding: 20px 20px;
    background-color: #fff;
    margin: 40px 0;
    position: relative;

    &__text {
        width: 60%;
        float: left;
        padding: 50px 0 0 20px;

        > span > p {
            padding: 0 20px;
            color: $text-light-dark;
        }

        @media (min-width: $screen-md-min) {
            width: 60%;
        }
    }

    &__animation {
        display: none;

        @media (min-width: $screen-md-min) {
            display: block;
            width: 40%;
            position: static;
            padding-left: 0;
            padding-bottom: 20px;
            text-align: center;
            float: left;
        }
    }

    &--garage-container {
        display: none;

        @media (min-width: $screen-md-min) {
            display: block;
        }
    }

    &--accordion {
        @media (min-width: $screen-md-min) {
            display: none;
        }
    }
}
