body,
html,
#root {
    height: 100%;
}

#root {
    padding-top: 80px;
}

@media screen and (max-width: 1140px) {
    body,
    html,
    #root {
        padding-top: 45px;
    }
}

.m-0 {
    margin: 0 !important;
}

.hide-mobile {
    @media (max-width: $screen-md-min) {
        display: none;
    }
}

.hide-desktop {
    @media (min-width: $screen-md-min) {
        display: none;
    }
}

@media (max-width: $screen-md-min) {
    .mobile-m--10 {
        margin: 0 -10px !important;
    }
}
