@import "../../../../assets/styles/base/variables";

.selected-service-list-container {
    position: relative;
    
    @media (min-width: $screen-md-min) {
        display: none;
    }
}

.selected-service-list {
    max-height: 107px;
    overflow: scroll;
    position: relative;
    
    &--padding-bottom {
        padding-bottom: 20px;
    }
}


.selected-service-shadow {
    pointer-events: none;
    position: absolute;
    content: " ";
    bottom: 0;
    height: 60px;
    z-index: 1;
    width: 100%;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgb(255, 255, 255)); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgb(255, 255, 255)); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgb(255, 255, 255)); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

