@import '../../../assets/styles/main/base/variables';

.licence-plate {
    margin-bottom: 15px;
    position: relative;

    > label {
        margin: -10px 0;
        float: left;
    }

    input[type=tel] {
        font-weight: 500;
        font-size: 16px;
    }
}

.licence-plate-input {
    width: 50%;
    float: left;
    padding-right: 10px;

    > .input-control:nth-child(1) {
        width: 48px;

        input {
            text-transform: uppercase;
        }
    }

    > .input-control:nth-child(2) {
        margin-left: 10px;
        width: calc(100% - 58px);
    }
}

.licence-plate-validation {
    width: 50%;
    float: left;
    padding-left: 10px;
    position: relative;
    height: 67px;
}

.licence-plate-validation-wrapper {
    transform: translateY(-50%);
    position: relative;
    top: 50%;

    > p {
        margin: 0;
        color: #637381;
        opacity: .5;
        font-size: $font-size;

        > span {
            color: $btn-disabled;
            position: relative;
            top: 2px;
            left: -5px;
        }

        &:first-child {
            line-height: 1.65;
        }
    }

    > p.default {
        opacity: 1;
        color: #cccccc;
        font-size: $font-size;

        > span {
            color: #cccccc;
        }
    }

    > p.valid {
        opacity: 1;
        color: $alert-success-primary;
        font-size: $font-size;

        > span {
            color: $alert-success-primary;
        }
    }

    > p.non-valid {
        opacity: 1;
        color: $alert-danger-primary;
        font-size: $font-size;

        > span {
            color: $alert-danger-primary;
        }
    }

}

.licence-plate-redirect {
    float: left;
    width: 100%;

    a {
        font-size: 14px;
        opacity: .5;

        &:hover{
            color: $btn-primary;
            opacity: 1;
        }
    }
}

.licence-plate-attempts {
    float: left;
    width: 100%;
    color: red;
    margin-bottom: 5px;
}

.licence-plate-error {
    width: 100%;
    position: absolute;
    top: 100%;

    > p {
        line-height: 1;
        margin: 0;
        font-size: 12px;
        color: red;
        font-weight: 500;
    }
}

.licence-plate-extra{
    text-align: right;
    line-height: 67px;
    font-size: $font-size-4;
    position: absolute;
    right: -10px;
    top: 0;

    .search-item-tooltip{
        color: #CCCCCC;
    }
}
