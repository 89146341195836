@import '../../../assets/styles/base/variables';

.preselected-service-list-container {
    @media (min-width: $screen-md-min) {
        margin: 20px 0 0 0;
    }
    
    > p {
        margin: 0 0 20px 0;
    }
}
.preselected-service-list {
    border-radius: 4px;
    box-shadow: 0 2px 24px -3px rgba(211, 211, 211, 0.5), 0 2px 5px -1px rgba(171, 171, 171, 0.5);
    background-color: #fff;
    padding: 10px 10px;
    margin-bottom: 30px;
}
