@import '../../assets/styles/base/variables';

.flash-messages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 25px 25px;

    @media (min-width: $screen-md-min) {
        padding: 0;
        width: 60%;
        right: 0;
        margin: 50px auto;
    }
}
